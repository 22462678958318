export const ZebraPrintGuestCard = async (printerSettings: CheckInPrinterSettingsDTO, fullName: string, companyName: string, phoneNumber: string) => {
    const printData = {
        name: fullName,
        company: companyName,
        phone: phoneNumber,
        connectionType: printerSettings.connectionType,
        printerIp: printerSettings.networkIpAddress, // if using network connection
        port: printerSettings.networkPort, // if using network connection
        usbPrinterName: printerSettings.usbPrinterName, // if using USB connection
        zplTemplate: printerSettings.printTemplate
    };
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), 2000)
    await fetch(printerSettings.localPrinterServiceUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(printData),
        signal: controller.signal
    })
        .then(data => {
            if (data.status === 200)
                console.log('Successfully printed');
            else console.log('Could not print: ' + data.status + " - " + data.statusText)
        })
        .catch(error => {
            console.error(error);
        });
    clearTimeout(timeoutId)

    return;

}