import store from '../configureStore';

export type LocalizationKey =
    | 'nextButton'
    | 'appointment'
    | 'noAppointment'
    | 'noAppointmentMessage1'
    | 'appointmentMessage1part1'
    | 'appointmentMessage1part2'
    | 'appointmentMessage1part3'
    | 'carrier'
    | 'checkout'
    | 'home'
    | 'firstName'
    | 'secondName'
    | 'next'
    | 'frontPageTitle'
    | 'frontPageTitleLouisP'
    | 'numberPageTitle'
    | 'keyCardPageTitle'
    | 'keyCardCheckBox'
    | 'mainfieldsPageTitle'
    | 'customFieldsPageTitle'
    | 'employeePageTitle'
    | 'employeePageTitleHost'
    | 'messagePageTitle'
    | 'checkinMessage'
    | 'checkoutMessage'
    | 'namePlaceholder'
    | 'touchTheScreen'
    | 'searchPlaceholder'
    | 'searchHostPlaceholder'
    | 'frontPageSubtext'
    | 'hr'
    | 'other'
    | 'checkinNoInfo'
    | 'language'
    | 'numberPlaceholder'
    | 'customOfficeFieldsPageTitle'
    | 'namePlaceholderExample'
    | 'companyPlaceholderExample'
    | 'goodbyeMessage1'
    | 'goodbyeMessage2'
    | 'goodbyeMessage3'
    | 'edFrontPageTitle'
    | 'edInserValue'
    | 'edCompanyPlaceholder'
    | 'contactButton'
    | 'goodbye1Edforhandler'
    | 'goodbye2Edforhandler'
    | 'goodbye3Edforhandler'
    | 'behandling'
    | 'fællesskab'
    | 'mentorsamtale'
    | 'praktikshjælp'
    | 'hf'
    | 'frivillig'
    | 'ja'
    | 'nej'
    | 'visionSubfirma'
    | 'visionIngenAftale'
    | 'welcome'
    | 'groupcheckin'
    | 'groupPlaceholder'
    | 'Erhvervmidtjylland'
    | 'ErhvervmidtjyllandNoAppointment'
    | 'Erhvervmidtjyllandmessage'
    | 'sefGDPR'
    | 'namePlaceholderED'
    | 'levenrandoer'
    | 'levenrandoerGoodbye'
    | 'checkinreisswolf'
    | 'checkudresswolf'
    | 'EDevent'
    | 'EDappointment'
    | 'EDnoappointment'
    | 'CompanyPlaceholder'
    | 'EdEventMessage'
    | 'checkoutlist'
    | 'checkoutlistname'
    | 'checkoutlistcompany'
    | 'checkoutlistdate'
    | 'checkoutlisttime'
    | 'continue'
    | 'back'
    | 'confirmCheckin'
    | 'confirmCheckout'
    | 'reisswolftCheckinSecondtitle'
    | 'reisswolftCheckinFollowtitle'
    | 'reisswolftCheckinFollowemployee'
    | 'reisswolftInputName'
    | 'reisswolftInputCompany'
    | 'kjaergaardThanksRegistered'
    | 'kjaergaardThanksForVisit'
    | 'kjaergaardNoAppointment'
    | 'kjaergaardGoToReception'
    | 'kjaergaardGoToHelp'
    | 'kjaergaardFetchYou'
    | 'kjaergaardCheckedOut'
    | 'acceptTemsAndConditions'
    | 'departmentsTitle'
    | 'flenstedDepartmentsSubTitle'
    | 'carpenter'
    | 'guest'
    | 'applicant'
    | 'purposeEmpty'
    | 'purposeCarpenter'
    | 'purposeGuest'
    | 'purposeApplicant'
    | 'lastInOfficeTitle'
    | 'redForVisitors'
    | 'blueForVisitors'
    | 'HaugenNoAppointment'
    | 'haugenCarrier'
    | 'HotWork'
    | 'NotHotWork'
    | 'CustomFlowcardPageTitle'
    | 'WelcomeDinexTMLHouse'
    | 'GuestRegistration'
    | 'visitorPhoneNumberInputLabel'
    | 'visitorNameInputLabel'
    | 'visitorCompanyNameInputLabel'
    | 'simpleCheckinCompleteMessage'
    | 'externalServiceButton'
    | 'carpenterSimple'

export class LocalizationDictionary {
    private static danishDictionary: Dictionary<LocalizationKey, string> = {
        'visitorPhoneNumberInputLabel': 'Telefonnummer',
        'visitorCompanyNameInputLabel': 'Firmanavn',
        'visitorNameInputLabel': 'Navn',
        'nextButton': 'Næste',
        'appointment': 'Har aftale',
        'appointmentMessage1part1': 'Vent venligst og tag plads, ',
        'appointmentMessage1part2': ' er blevet informeret om din ankomst og er på vej. Tag key hanger efter anvisningen nedenfor.',
        'appointmentMessage1part3': 'er informeret og vil være hos dig om lidt. Venligst tag plads.',
        'noAppointment': "Ingen aftale",
        'noAppointmentMessage1': 'Henvend dig i receptionen.',
        //'carrier': "Fragtmand",
        'carrier': "Fragtmand",
        'checkout': 'Tjek ud',
        'home': 'Start',
        'firstName': 'Fornavn',
        'secondName': 'Efternavn',
        'next': 'Næste',
        'frontPageTitle': 'Velkommen til ',
        'frontPageTitleLouisP': 'Check ind her',
        'numberPageTitle': 'Indtast dit mobilnummer',
        'keyCardPageTitle': 'Indtast nøglekorts nummer',
        'keyCardCheckBox': 'Jeg har ikke et nøglekort',
        'mainfieldsPageTitle': 'Indtast dine oplysninger',
        'customFieldsPageTitle': 'Indtast dine oplysninger',
        'employeePageTitle': 'Vælg den ønskede medarbejder',
        'employeePageTitleHost': 'Vælg din vært',
        'messagePageTitle': 'Vent venligst og tag plads',
        'checkinMessage': 'Vent venligst og tag plads, en medarbejder er blevet informeret om din ankomst og er på vej',
        'checkoutMessage': 'Tak, din udtjekning er blevet registreret',
        'namePlaceholder': 'Indtast navn og efternavn',
        'touchTheScreen': 'Tryk på skærmen',
        'searchPlaceholder': 'Søg medarbejder',
        'searchHostPlaceholder': 'Søg efter vært',
        'frontPageSubtext': 'Venligst meld din ankomst her',
        'hr': "HR / Job ansøgninger",
        'other': "Andet",
        'checkinNoInfo': "Aftalt møde",
        'language': "DK",
        'numberPlaceholder': 'Eksempel +45 88 88 88 88',
        'customOfficeFieldsPageTitle': 'Indtast din virksomhed',
        'namePlaceholderExample': 'Eksempel: John Jensen',
        'companyPlaceholderExample': 'Eksempel: Jysk A/S',
        'goodbyeMessage1': 'Du er nu registreret, og din vært er blevet kontaktet',
        'goodbyeMessage2': 'Tag venligst plads i receptionen.',
        'goodbyeMessage3': 'Vent venligst her',
        'edFrontPageTitle': 'Venligst vælg:',
        'edInserValue': 'Indtast venligst:',
        'edCompanyPlaceholder': 'Dit firmanavn (valgfrit)',
        'contactButton': 'Godkend',
        'goodbye1Edforhandler': 'Din vært har fået besked om dit ankomst.',
        'goodbye2Edforhandler': 'Vent venligst, en medarbejder vil åbne døren for dig.',
        'goodbye3Edforhandler': 'Vent venligst, en medarbejder vil åbne døren for dig.',
        'behandling': 'BEHANDLINGSRUM',
        'fællesskab': 'FÆLLESSKAB & AKTIVITETER',
        'mentorsamtale': 'MENTORSAMTALE',
        'praktikshjælp': 'PRAKTISK HJÆLP',
        'hf': 'JEG GÅR PÅ HF',
        'frivillig': 'FUNDABOLIG',
        'ja': 'JA',
        'nej': 'NEJ',
        'visionSubfirma': 'Tryk venligst på den virksomhed du skal besøge',
        'visionIngenAftale': 'Har du ingen aftale - klik her',
        'welcome': 'Velkommen',
        'groupcheckin': 'Gruppe aftale',
        'groupPlaceholder': 'Indtast antal deltagere',
        'Erhvervmidtjylland': 'Har du en aftale med en medarbejder? Eller ønsker du hjælp? Vælg her.',
        'ErhvervmidtjyllandNoAppointment': 'Ønsker hjælp',
        'Erhvervmidtjyllandmessage': ' er informeret om din ankomst',
        'sefGDPR': 'Dine kontaktoplysninger (navn og telefonnummer) indsamles og opbevares i forbindelse med dit besøg, så SEF har et overblik over hvem der er i bygningen i tilfælde af en beredskabssituation. Dine data slettes når du tjekker ud efter dit besøg. Du kan læse mere om SEF’s privatlivspolitik på dette link:',
        'namePlaceholderED': 'Indtast dit navn',
        'levenrandoer': 'Leverandør',
        'levenrandoerGoodbye': 'Velkommen, tag venligst plads ved leverandør bordet.',
        'checkinreisswolf': 'CHECK IND',
        'checkudresswolf': 'CHECK UD',
        'EDevent': 'Jeg skal deltage i et event',
        'EDappointment': 'Jeg har en aftale',
        'EDnoappointment': 'Jeg har ikke en aftale',
        'CompanyPlaceholder': 'Dit firmanavn',
        'EdEventMessage': 'Tak for registrering.**Du kan hænge dit overtøj i garderoben :-)',
        'checkoutlist': 'VÆLG DIT NAVN PÅ LISTEN',
        'checkoutlistname': 'NAVN',
        'checkoutlistcompany': 'VIRKSOMHED',
        'checkoutlistdate': 'DATO',
        'checkoutlisttime': 'TIDSPUNKT',
        'continue': 'FORTSÆT',
        'back': 'TILBAGE',
        'confirmCheckin': 'BEKRÆFT CHECK IND FOR',
        'confirmCheckout': 'BEKRÆFT CHECK UD FOR',
        'reisswolftCheckinSecondtitle': 'INDTAST OPLYSNINGER',
        'reisswolftCheckinFollowtitle': 'VÆLG MEDARBEJDER AT FØLGES MED',
        'reisswolftCheckinFollowemployee': ' kommer og henter dig',
        'reisswolftInputName': 'FORNAVN & EFTERNAVN',
        'reisswolftInputCompany': 'VIRKSOMHED',
        'kjaergaardThanksRegistered': 'Tak, du er nu registreret',
        'kjaergaardThanksForVisit': 'Tak for besøget',
        'kjaergaardNoAppointment': 'Har du ingen aftale?',
        'kjaergaardGoToReception': 'Henvend dig venligst i receptionen, på 2. sal til venstre, hvor',
        'kjaergaardGoToHelp': 'Henvend dig venligst i receptionen,\npå 2. sal til venstre, for hjælp.',
        'kjaergaardFetchYou': 'vil hente dig.\nEfter endt besøg, venligst tjek ud her igen.',
        'kjaergaardCheckedOut': 'Du er nu tjekket ud\nVi ønsker dig en rigtig god dag',
        'acceptTemsAndConditions': 'Accepterer du betingelserne?',
        'departmentsTitle': 'Vælg afdeling',
        'flenstedDepartmentsSubTitle': 'Vælg den afdeling hvor din kontakt er tilknyttet',
        'carpenter': 'Er du håndværker?',
        'guest': 'Er du gæst?',
        'applicant': 'Er du ansøger?',
        'purposeEmpty': '',
        'purposeCarpenter': 'Håndværker',
        'purposeGuest': 'Gæst',
        'purposeApplicant': 'Ansøger',
        'lastInOfficeTitle': 'Marker nedenfor hvis du er den sidste person der forlader virksomheden',
        'redForVisitors': 'Rød for besøgende',
        'blueForVisitors': 'Blå for eksterne medarbejdere',
        'HaugenNoAppointment': 'Ring venligst til kundeservice på\n+45 35 34 30 00',
        'haugenCarrier': 'Gå venligst til indgangen ved lastbil chaufførene\n(døren til venstre for hovedindgangen)',
        'HotWork': 'Jeg skal arbejde med varmt arbejde',
        'NotHotWork': 'Jeg skal ikke arbejde med varmt arbejde',
        'CustomFlowcardPageTitle': 'Vælg venligst, hvis du er i tvivl kontakt venligst receptionen.',
        'WelcomeDinexTMLHouse': 'Velkommen til Dinex A/S &\nTML Safety Engineering A/S',
        'GuestRegistration': 'Gæste registrering',
        'simpleCheckinCompleteMessage': 'Du er nu tjekket ind - hav en god dag!',
        'externalServiceButton': 'Ekstern Service',
        'carpenterSimple': 'Håndværker'
    }

    private static englishDictionary: Dictionary<LocalizationKey, string> = {
        'visitorPhoneNumberInputLabel': 'Phone number',
        'visitorCompanyNameInputLabel': 'Company name',
        'visitorNameInputLabel': 'Name',
        'nextButton': 'Next',
        'appointment': 'I have an appointment',
        'appointmentMessage1part1': 'Please wait and have a seat, ',
        'appointmentMessage1part2': ' has been informed of your arrival and is on the way. Grab a key hanger as instructed beneath.',
        'appointmentMessage1part3': 'is informed and will be with you shortly. Please have a seat.',
        'noAppointment': "No appointment",
        'noAppointmentMessage1': 'Please contact the reception.',
        'carrier': "I'm a courier",
        'checkout': 'Checkout',
        'home': 'Home',
        'firstName': 'Name',
        'secondName': 'Surname',
        'next': 'Next',
        'frontPageTitle': 'Welcome to ',
        'frontPageTitleLouisP': 'Check in here ',
        'numberPageTitle': 'Please enter your phone number',
        'keyCardPageTitle': 'Enter key card number',
        'keyCardCheckBox': 'I do not have a key card',
        'mainfieldsPageTitle': 'Please enter your information',
        'customFieldsPageTitle': 'Please enter your information',
        'employeePageTitle': 'Choose an employee',
        'employeePageTitleHost': 'Choose your host',
        'messagePageTitle': 'Please wait and take a seat',
        'checkinMessage': 'Please wait and have a seat, someone has been informed of your arrival and is on the way',
        'checkoutMessage': 'Thanks, you are checked out',
        'namePlaceholder': 'First name and surname',
        'touchTheScreen': 'Touch the screen',
        'searchPlaceholder': 'Search employee',
        'searchHostPlaceholder': 'Search hosts',
        'frontPageSubtext': 'Please register your arrival here',
        'hr': "HR / Job applications",
        'other': "Other",
        'checkinNoInfo': "I have an appointment",
        'language': "EN",
        'numberPlaceholder': 'Example +45 88 88 88 88',
        'customOfficeFieldsPageTitle': 'Please enter your company',
        'namePlaceholderExample': 'Example: John Jensen',
        'companyPlaceholderExample': 'Example: Jysk A/S',
        'goodbyeMessage1': 'You are now registered and your host has been contacted.',
        'goodbyeMessage2': 'Please have a seat in the Reception.',
        'goodbyeMessage3': 'Please wait here.',
        'edFrontPageTitle': 'Please select:',
        'edInserValue': 'Please enter:',
        'edCompanyPlaceholder': 'Company name (optional)',
        'contactButton': 'OK',
        'goodbye1Edforhandler': 'Your host has received a notification of your arrival.',
        'goodbye2Edforhandler': 'Please wait, an employee will open the door for you.',
        'goodbye3Edforhandler': 'Please wait, an employee will open the door for you.',
        'behandling': '',
        'fællesskab': '',
        'mentorsamtale': '',
        'praktikshjælp': '',
        'hf': '',
        'frivillig': '',
        'ja': 'YES',
        'nej': 'NO',
        'visionSubfirma': 'Please click on the company you are visiting',
        'visionIngenAftale': 'If you have no appointment - click here',
        'welcome': 'Welcome',
        'groupcheckin': 'Group appointment',
        'groupPlaceholder': 'Number of participants',
        'Erhvervmidtjylland': 'Do you have an appointment with an employee? Or do you need help? Choose here.',
        'ErhvervmidtjyllandNoAppointment': 'Need help',
        'Erhvervmidtjyllandmessage': ' has been informed of your arrival',
        'sefGDPR': "Your contact information (name and telephone number) is collected and stored in connection with your visit, so that SEF has an overview of who is in the building in the event of an emergency situation. Your data is deleted when you check out after your visit. You can read more about SEF's privacy policy at this link:",
        'namePlaceholderED': 'Insert your name',
        'levenrandoer': 'supplier',
        'levenrandoerGoodbye': 'Welcome, please take a seat at the supplier table.',
        'checkinreisswolf': 'CHECK IN',
        'checkudresswolf': 'CHECK OUT',
        'EDevent': "I'm attending an event",
        'EDappointment': 'I have an appointment',
        'EDnoappointment': 'I do not have an appointment',
        'CompanyPlaceholder': 'Your company name',
        'EdEventMessage': 'Thank you for registration. **You can hang your outerwear in the wardrobe :-)',
        'checkoutlist': 'CHOOSE YOUR NAME ON THE LIST',
        'checkoutlistname': 'NAME',
        'checkoutlistcompany': 'COMPANY',
        'checkoutlistdate': 'DATE',
        'checkoutlisttime': 'TIME',
        'continue': 'CONTINUE',
        'back': 'BACK',
        'confirmCheckin': 'CONFIRM CHECK IN FOR',
        'confirmCheckout': 'CONFIRM CHECK OUT FOR',
        'reisswolftCheckinSecondtitle': 'ENTER DETAILS',
        'reisswolftCheckinFollowtitle': 'CHOOSE EMPLOYEE TO FOLLOW',
        'reisswolftCheckinFollowemployee': ' will come and get you',
        'reisswolftInputName': 'FIRSTNAME & LASTNAME',
        'reisswolftInputCompany': 'COMPANY',
        'kjaergaardThanksRegistered': 'Thanks, you are now registered',
        'kjaergaardThanksForVisit': 'Thanks for the visit',
        'kjaergaardNoAppointment': 'Do you not have an appointment?',
        'kjaergaardGoToReception': 'Please contact the receptionen, on the 2nd floor to the left, where',
        'kjaergaardGoToHelp': 'Please contact the receptionen,\non the 2nd floor to the left, for help.',
        'kjaergaardFetchYou': 'will get you.\nBefore leaving, please check out here again.',
        'kjaergaardCheckedOut': 'You are now checked out\nWe hope you have a great day',
        'acceptTemsAndConditions': 'Do you accept the terms?',
        'departmentsTitle': 'Choose department',
        'flenstedDepartmentsSubTitle': 'Choose the department where your contact is registered',
        'carpenter': 'Are you a contractor?',
        'guest': 'Are you a guest?',
        'applicant': 'Are you an applicant?',
        'purposeEmpty': '',
        'purposeCarpenter': 'Contractor',
        'purposeGuest': 'Guest',
        'purposeApplicant': 'Applicant',
        'lastInOfficeTitle': 'Mark below if you are the last person leaving the company',
        'redForVisitors': 'Red for visitors',
        'blueForVisitors': 'Blue for external workers',
        'HaugenNoAppointment': 'Please call customer service\n+45 35 34 30 00',
        'haugenCarrier': 'Please go to the truck driver’s entrance\n(door to the left of the main entrance)',
        'HotWork': 'I need to work with hot work',
        'NotHotWork': 'I do not need to work with hot work',
        'CustomFlowcardPageTitle': 'Please choose, if you are in doubt please contact the reception.',
        'WelcomeDinexTMLHouse': 'Welcome to Dinex A/S &\nTML Safety Engineering A/S',
        'GuestRegistration': 'Guest Registration',
        'simpleCheckinCompleteMessage': 'You are now checked in - have a great day!',
        'externalServiceButton': 'External Service',
        'carpenterSimple': 'Carpenter'
    }

    private static germanDictionary: Dictionary<LocalizationKey, string> = {
        'visitorPhoneNumberInputLabel': 'Telefonnummer',
        'visitorCompanyNameInputLabel': 'Firmenname',
        'visitorNameInputLabel': 'Name',
        'nextButton': 'Weiter',
        'appointment': 'Ich habe einen Termin',
        'appointmentMessage1part1': 'Bitte warten Sie einen Moment und nehmen Sie Platz, ',
        'appointmentMessage1part2': ' Sie wurden über Ihre Ankunft informiert und sind auf dem Weg. Bitte nehmen Sie den Schlüsselanhänger gemäß den Anweisungen unten.',
        'appointmentMessage1part3': 'ist informiert und wird in Kürze bei Ihnen sein. Bitte nehmen Sie Platz.',
        'noAppointment': 'Kein Termin',
        'noAppointmentMessage1': 'Bitte wenden Sie sich an die Rezeption.',
        'carrier': 'Ich bin ein Kurier',
        'checkout': 'Auschecken',
        'home': 'Startseite',
        'firstName': 'Vorname',
        'secondName': 'Nachname',
        'next': 'Weiter',
        'frontPageTitle': 'Willkommen bei ',
        'frontPageTitleLouisP': 'Hier einchecken',
        'numberPageTitle': 'Bitte geben Sie Ihre Telefonnummer ein',
        'keyCardPageTitle': 'Geben Sie die Kartennummer ein',
        'keyCardCheckBox': 'Ich habe keine Schlüsselkarte',
        'mainfieldsPageTitle': 'Bitte geben Sie Ihre Informationen ein',
        'customFieldsPageTitle': 'Bitte geben Sie Ihre Informationen ein',
        'employeePageTitle': 'Wählen Sie einen Mitarbeiter aus',
        'employeePageTitleHost': 'Wählen Sie Ihren Gastgeber',
        'messagePageTitle': 'Bitte warten Sie und nehmen Sie Platz',
        'checkinMessage': 'Bitte warten Sie und nehmen Sie Platz. Jemand wurde über Ihre Ankunft informiert und ist auf dem Weg.',
        'checkoutMessage': 'Vielen Dank, Sie haben ausgecheckt',
        'namePlaceholder': 'Vorname und Nachname',
        'touchTheScreen': 'Bildschirm berühren',
        'searchPlaceholder': 'Mitarbeiter suchen',
        'searchHostPlaceholder': 'Gastgeber suchen',
        'frontPageSubtext': 'Bitte melden Sie sich hier an',
        'hr': 'Personalabteilung / Bewerbungen',
        'other': 'Sonstiges',
        'checkinNoInfo': 'Ich habe einen Termin',
        'language': 'DE',
        'numberPlaceholder': 'Beispiel: +45 88 88 88 88',
        'customOfficeFieldsPageTitle': 'Bitte geben Sie Ihr Unternehmen ein',
        'namePlaceholderExample': 'Beispiel: John Jensen',
        'companyPlaceholderExample': 'Beispiel: Jysk A/S',
        'goodbyeMessage1': 'Sie sind jetzt registriert, und Ihr Gastgeber wurde benachrichtigt.',
        'goodbyeMessage2': 'Bitte nehmen Sie im Empfangsbereich Platz.',
        'goodbyeMessage3': 'Bitte warten Sie hier.',
        'edFrontPageTitle': 'Bitte auswählen:',
        'edInserValue': 'Bitte eingeben:',
        'edCompanyPlaceholder': 'Firmenname (optional)',
        'contactButton': 'OK',
        'goodbye1Edforhandler': 'Ihr Gastgeber wurde über Ihre Ankunft informiert.',
        'goodbye2Edforhandler': 'Bitte warten Sie, ein Mitarbeiter wird Ihnen die Tür öffnen.',
        'goodbye3Edforhandler': 'Bitte warten Sie, ein Mitarbeiter wird Ihnen die Tür öffnen.',
        'behandling': '',
        'fællesskab': '',
        'mentorsamtale': '',
        'praktikshjælp': '',
        'hf': '',
        'frivillig': '',
        'ja': 'JA',
        'nej': 'NEIN',
        'visionSubfirma': 'Bitte klicken Sie auf das Unternehmen, das Sie besuchen',
        'visionIngenAftale': 'Wenn Sie keinen Termin haben - hier klicken',
        'welcome': 'Willkommen',
        'groupcheckin': 'Gruppen-Termin',
        'groupPlaceholder': 'Anzahl der Teilnehmer',
        'Erhvervmidtjylland': 'Haben Sie einen Termin mit einem Mitarbeiter? Oder benötigen Sie Hilfe? Hier auswählen.',
        'ErhvervmidtjyllandNoAppointment': 'Hilfe benötigt',
        'Erhvervmidtjyllandmessage': 'wurde über Ihre Ankunft informiert',
        'sefGDPR': 'Ihre Kontaktdaten (Name und Telefonnummer) werden erfasst und gespeichert, um SEF im Notfall einen Überblick darüber zu verschaffen, wer sich im Gebäude befindet. Ihre Daten werden gelöscht, wenn Sie nach Ihrem Besuch auschecken. Weitere Informationen zur Datenschutzrichtlinie von SEF finden Sie unter folgendem Link:',
        'namePlaceholderED': 'Ihren Namen eingeben',
        'levenrandoer': 'Lieferant',
        'levenrandoerGoodbye': 'Willkommen, bitte nehmen Sie am Lieferantentisch Platz.',
        'checkinreisswolf': 'EINCHECKEN',
        'checkudresswolf': 'AUSCHECKEN',
        'EDevent': 'Ich nehme an einer Veranstaltung teil',
        'EDappointment': 'Ich habe einen Termin',
        'EDnoappointment': 'Ich habe keinen Termin',
        'CompanyPlaceholder': 'Ihr Firmenname',
        'EdEventMessage': 'Vielen Dank für die Anmeldung. **Sie können Ihre Oberbekleidung in der Garderobe aufhängen :-)',
        'checkoutlist': 'IHREN NAMEN IN DER LISTE AUSWÄHLEN',
        'checkoutlistname': 'NAME',
        'checkoutlistcompany': 'UNTERNEHMEN',
        'checkoutlistdate': 'DATUM',
        'checkoutlisttime': 'UHRZEIT',
        'continue': 'WEITER',
        'back': 'ZURÜCK',
        'confirmCheckin': 'BESTÄTIGEN SIE EINCHECKEN FÜR',
        'confirmCheckout': 'BESTÄTIGEN SIE AUSCHECKEN FÜR',
        'reisswolftCheckinSecondtitle': 'DETAILS EINGEBEN',
        'reisswolftCheckinFollowtitle': 'MITARBEITER ZUM FOLGEN AUSWÄHLEN',
        'reisswolftCheckinFollowemployee': 'wird zu Ihnen kommen',
        'reisswolftInputName': 'VORNAME & NACHNAME',
        'reisswolftInputCompany': 'UNTERNEHMEN',
        'kjaergaardThanksRegistered': 'Vielen Dank, Sie sind jetzt registriert',
        'kjaergaardThanksForVisit': 'Vielen Dank für den Besuch',
        'kjaergaardNoAppointment': 'Haben Sie keinen Termin?',
        'kjaergaardGoToReception': 'Bitte wenden Sie sich an die Rezeption im 2. Stock links, wo',
        'kjaergaardGoToHelp': 'Bitte wenden Sie sich an die Rezeption,\nim 2. Stock links, um Hilfe zu erhalten.',
        'kjaergaardFetchYou': 'wird Sie abholen.\nBevor Sie gehen, melden Sie sich bitte auch hier wieder ab.',
        'kjaergaardCheckedOut': 'Sie sind jetzt ausgecheckt\nWir hoffen, Sie haben einen schönen Tag',
        'acceptTemsAndConditions': 'Akzeptieren Sie die Bedingungen?',
        'departmentsTitle': 'Abteilung auswählen',
        'flenstedDepartmentsSubTitle': 'Wählen Sie die Abteilung, in der Ihr Kontakt registriert ist',
        'carpenter': 'Bist du Handwerker?',
        'guest': 'Bist du Gast?',
        'applicant': 'Bist du Bewerber?',
        'purposeEmpty': '',
        'purposeCarpenter': 'Handwerker',
        'purposeGuest': 'Gast',
        'purposeApplicant': 'Bewerber',
        'lastInOfficeTitle': 'Markieren Sie unten, wenn Sie die letzte Person sind, die das Unternehmen verlässt',
        'redForVisitors': 'Rot für Besucher',
        'blueForVisitors': 'Blau für externe Mitarbeiter',
        'HaugenNoAppointment': 'Rufen Sie bitte den Kundenservice unter an\n+45 35 34 30 00',
        'haugenCarrier': 'Gehen Sie bitte zum Eingang bei den LKW-Fahrern\n(die Tür links vom Haupteingang)',
        'HotWork': 'Ich muss Heißarbeiten durchführen',
        'NotHotWork': 'Ich muss keine Heißarbeiten durchführen',
        'CustomFlowcardPageTitle': 'Bitte wählen Sie, bei Zweifeln wenden Sie sich bitte an die Rezeption.',
        'WelcomeDinexTMLHouse': 'Willkommen bei Dinex A/S &\nTML Safety Engineering A/S',
        'GuestRegistration': 'Gästeanmeldung',
        'simpleCheckinCompleteMessage': 'Du bist jetzt eingecheckt - hab einen schönen Tag!',
        'externalServiceButton': 'Externer Dienst',
        'carpenterSimple': 'Handwerker'
    }

    private static polishDictionary: Dictionary<LocalizationKey, string> = {
        'visitorPhoneNumberInputLabel': 'Numer telefonu',
        'visitorCompanyNameInputLabel': 'Nazwa firmy',
        'visitorNameInputLabel': 'Imię i nazwisko',
        'nextButton': 'Dalej',
        'appointment': 'Mam umówione spotkanie',
        'appointmentMessage1part1': 'Proszę zaczekać i zająć miejsce, ',
        'appointmentMessage1part2': ' Zostałeś poinformowany o twoim przybyciu i jesteśmy w drodze. Proszę odebrać uchwyt na klucze zgodnie z poniższą instrukcją.',
        'appointmentMessage1part3': 'jest poinformowany i wkrótce do Ciebie przyjdzie. Proszę usiąść.',
        'noAppointment': 'Brak umówionego spotkania',
        'noAppointmentMessage1': 'Proszę udać się do recepcji.',
        'carrier': 'Jestem kurierem',
        'checkout': 'Zakupy',
        'home': 'Strona główna',
        'firstName': 'Imię',
        'secondName': 'Nazwisko',
        'next': 'Dalej',
        'frontPageTitle': 'Witaj w ',
        'frontPageTitleLouisP': 'Zamelduj się tutaj',
        'numberPageTitle': 'Proszę podać numer telefonu',
        'keyCardPageTitle': 'Wprowadź numer karty',
        'keyCardCheckBox': 'Nie mam karty dostępu',
        'mainfieldsPageTitle': 'Proszę podać swoje informacje',
        'customFieldsPageTitle': 'Proszę podać swoje informacje',
        'employeePageTitle': 'Wybierz pracownika',
        'employeePageTitleHost': 'Wybierz swojego gospodarza',
        'messagePageTitle': 'Proszę czekać i zająć miejsce',
        'checkinMessage': 'Proszę czekać i zająć miejsce, ktoś został poinformowany o Twoim przyjeździe i jest w drodze',
        'checkoutMessage': 'Dziękujemy, zostałeś wyrejestrowany',
        'namePlaceholder': 'Imię i nazwisko',
        'touchTheScreen': 'Dotknij ekranu',
        'searchPlaceholder': 'Szukaj pracownika',
        'searchHostPlaceholder': 'Szukaj gospodarzy',
        'frontPageSubtext': 'Proszę zarejestrować swoje przybycie tutaj',
        'hr': 'HR / Aplikacje o pracę',
        'other': 'Inne',
        'checkinNoInfo': 'Mam umówione spotkanie',
        'language': 'PL',
        'numberPlaceholder': 'Przykład: +45 88 88 88 88',
        'customOfficeFieldsPageTitle': 'Proszę podać nazwę firmy',
        'namePlaceholderExample': 'Przykład: Jan Kowalski',
        'companyPlaceholderExample': 'Przykład: ABC Sp. z o.o.',
        'goodbyeMessage1': 'Jesteś teraz zarejestrowany, a Twój gospodarz został poinformowany.',
        'goodbyeMessage2': 'Proszę zająć miejsce w recepcji.',
        'goodbyeMessage3': 'Proszę czekać tutaj.',
        'edFrontPageTitle': 'Proszę wybrać:',
        'edInserValue': 'Proszę wprowadzić:',
        'edCompanyPlaceholder': 'Nazwa firmy (opcjonalnie)',
        'contactButton': 'OK',
        'goodbye1Edforhandler': 'Twój gospodarz został powiadomiony o Twoim przyjeździe.',
        'goodbye2Edforhandler': 'Proszę czekać, pracownik otworzy Ci drzwi.',
        'goodbye3Edforhandler': 'Proszę czekać, pracownik otworzy Ci drzwi.',
        'behandling': '',
        'fællesskab': '',
        'mentorsamtale': '',
        'praktikshjælp': '',
        'hf': '',
        'frivillig': '',
        'ja': 'TAK',
        'nej': 'NIE',
        'visionSubfirma': 'Proszę kliknąć na firmę, którą odwiedzasz',
        'visionIngenAftale': 'Jeśli nie masz umówionego spotkania - kliknij tutaj',
        'welcome': 'Witaj',
        'groupcheckin': 'Grupowe spotkanie',
        'groupPlaceholder': 'Liczba uczestników',
        'Erhvervmidtjylland': 'Czy masz umówione spotkanie z pracownikiem? Czy potrzebujesz pomocy? Wybierz tutaj.',
        'ErhvervmidtjyllandNoAppointment': 'Potrzebuję pomocy',
        'Erhvervmidtjyllandmessage': 'został poinformowany o Twoim przyjeździe',
        'sefGDPR': 'Twoje dane kontaktowe (imię i numer telefonu) są zbierane i przechowywane w związku z Twoją wizytą, aby SEF miało przegląd osób przebywających w budynku w przypadku sytuacji awaryjnej. Twoje dane są usuwane po wyrejestrowaniu się po wizycie. Więcej informacji na temat polityki prywatności SEF znajdziesz pod tym linkiem:',
        'namePlaceholderED': 'Wprowadź swoje imię',
        'levenrandoer': 'Dostawca',
        'levenrandoerGoodbye': 'Witaj, proszę zająć miejsce przy stole dostawcy.',
        'checkinreisswolf': 'ZAMELDUJ SIĘ',
        'checkudresswolf': 'WYMELDOWANIE',
        'EDevent': 'Uczestniczę w wydarzeniu',
        'EDappointment': 'Mam umówione spotkanie',
        'EDnoappointment': 'Nie mam umówionego spotkania',
        'CompanyPlaceholder': 'Nazwa Twojej firmy',
        'EdEventMessage': 'Dziękujemy za rejestrację. **Możesz zawiesić swoją odzież w szatni :-)',
        'checkoutlist': 'WYBIERZ SWOJĄ NAZWĘ Z LISTY',
        'checkoutlistname': 'NAZWA',
        'checkoutlistcompany': 'FIRMA',
        'checkoutlistdate': 'DATA',
        'checkoutlisttime': 'CZAS',
        'continue': 'KONTYNUUJ',
        'back': 'COFNIJ',
        'confirmCheckin': 'POTWIERDŹ ZAMELDOWANIE DLA',
        'confirmCheckout': 'POTWIERDŹ WYMELDOWANIE DLA',
        'reisswolftCheckinSecondtitle': 'WPROWADŹ SZCZEGÓŁY',
        'reisswolftCheckinFollowtitle': 'WYBIERZ PRACOWNIKA DO ŚLEDZENIA',
        'reisswolftCheckinFollowemployee': 'przyjdzie po Ciebie',
        'reisswolftInputName': 'IMIĘ I NAZWISKO',
        'reisswolftInputCompany': 'FIRMA',
        'kjaergaardThanksRegistered': 'Dziękujemy, teraz jesteś zarejestrowany',
        'kjaergaardThanksForVisit': 'Dziękujemy za wizytę',
        'kjaergaardNoAppointment': 'Nie masz umówionego spotkania?',
        'kjaergaardGoToReception': 'Proszę skontaktować się z recepcją na 2. piętrze po lewej stronie, gdzie',
        'kjaergaardGoToHelp': 'Proszę skontaktować się z recepcją,\nna 2. piętrze po lewej stronie, aby uzyskać pomoc.',
        'kjaergaardFetchYou': 'przyjdzie po Ciebie.\nPrzed opuszczeniem miejsca, proszę się tutaj wyrejestrować.',
        'kjaergaardCheckedOut': 'Jesteś teraz wyrejestrowany\nMamy nadzieję, że masz udany dzień',
        'acceptTemsAndConditions': 'Czy akceptujesz warunki?',
        'departmentsTitle': 'Wybierz dział',
        'flenstedDepartmentsSubTitle': 'Wybierz dział, w którym jest zarejestrowany Twój kontakt',
        'carpenter': 'Jesteś stolarzem?',
        'guest': 'Jesteś gościem?',
        'applicant': 'Jesteś kandydatem?',
        'purposeEmpty': '',
        'purposeCarpenter': 'Rzemieślnik',
        'purposeGuest': 'Gość',
        'purposeApplicant': 'Wnioskodawca',
        'lastInOfficeTitle': 'Zaznacz poniżej, jeśli jesteś ostatnią osobą opuszczającą firmę',
        'redForVisitors': 'Czerwony dla odwiedzających',
        'blueForVisitors': 'Niebieski dla pracowników zewnętrznych',
        'HaugenNoAppointment': 'Proszę zadzwonić do obsługi klienta pod numer\n+45 35 34 30 00',
        'haugenCarrier': 'Proszę udać się do wejścia przy kierowcach ciężarówek\n(drzwi po lewej stronie od głównego wejścia)',
        'HotWork': 'Muszę wykonywać prace na gorąco',
        'NotHotWork': 'Nie muszę wykonywać prac na gorąco',
        'CustomFlowcardPageTitle': 'Proszę wybrać, w razie wątpliwości proszę skontaktować się z recepcją.',
        'WelcomeDinexTMLHouse': 'Witamy w Dinex A/S &\nTML Safety Engineering A/S',
        'GuestRegistration': 'Rejestracja gości',
        'simpleCheckinCompleteMessage': 'Jesteś już zameldowany - miłego dnia!',
        'externalServiceButton': 'Usługa zewnętrzna',
        'carpenterSimple': 'Stolar'
    }

    public static getStrings = () => {
        switch (store.getState().localization.language) {
            case 'EN':
                return LocalizationDictionary.englishDictionary
            case 'DE':
                return LocalizationDictionary.germanDictionary
            case 'PL':
                return LocalizationDictionary.polishDictionary
            default:
                return LocalizationDictionary.danishDictionary
        }
    }
}
