import { Reducer } from 'redux';
import {
    ConfigDataActions,
    ConfigDataActionTypes as actions,
    IConfigDataState,
} from './types';

export const initialState: IConfigDataState = {
    configData: {
        name: "",
        contactPhone: "",
        responsiblePhoneNumber: "",
        responsibleEmail: "",
        courierPhoneNumber: "",
        courierEmail: "",
        responsibleSMSEnabled: false,
        responsibleEmailEnabled: false,
        courierSMSEnabled: false,
        courierEmailEnabled: false,
        useClientDepartments: false,
        useKeyCard: false,
        checkInConfigResponse: {
            courierFlowEnabled: false,
            doesNotHaveAppointmentEnabled: false,
            checkoutEnabled: false,
            informAllEnabled: false,
            mainColor: "",
            secondaryColor: "",
            tertiaryColor: "",
            mainTextColor: "",
            secondaryTextColor: "",
            tertiaryTextColor: "",
            defaultLanguage: "",
            layoutVersion: "",
            logoId: 0,
            templateBackgroundId: 0,
            employeePhotoEnabled: false,
            employeeDepartmentEnabled: false,
            employeeJobTitlenabled: false,
            employeePhoneNumberEnabled: false
        },
        checkInEmailConfigResponse: {
            emailText: "",
            checkInTimeEnabled: false,
            phoneNumberEnabled: false,
            customFieldsEnabled: false
        },
        checkInSmsConfigResponse: {
            smsText: "",
            checkInTimeEnabled: false,
            phoneNumberEnabled: false,
            customFieldsEnabled: false
        },
        checkInPrintConfigResponse: {
            printText: "",
            phoneNumberEnabled: false,
            employeeEnabled: false
        },
        checkInCustomAttributes: [],
        checkInPrinterSettings: {
            networkIpAddress: "",
            networkPort: 0,
            connectionType: null,
            usbPrinterName: "",
            printTemplate: "",
            localPrinterServiceUrl: ""
        }
    },
    languageData: []
};

const configDataReducer: Reducer<IConfigDataState> = (
    state: IConfigDataState = initialState,
    action: ConfigDataActions
) => {
    switch (action.type) {
        case actions.SET_CONFIG_DATA:
            return { ...state, configData: action.payload.configData };
        case actions.SET_LANGUAGE_DATA:
            return { ...state, languageData: action.payload.languageData };
        default:
            return state;
    }
}

export default configDataReducer;
