import React, { Component } from 'react';
import LanguagePicker from './LayoutElements/LaguagePicker/LanguagePicker';
import ContentFlowPage from './ContentPage/ContentFlowPage';
import './index.css';
import HomeButton from './LayoutElements/HomeButton/HomeButton';
import { Fabric, initializeIcons } from '@fluentui/react';
import { dispatchRequest } from '../api/apiUtils';
import { getAccountValue, getCheckInLayoutRequest, getCompanyGuidFromId, getConditionRequest, getConfigRequest, getDepartmentsRequest, getFullyDetailedConfigRequest, getLanguageOptionsRequest } from '../api/companyApi';
import ClientConfig from '../config/clientConfig';
import { IFlowState } from '../store/Flow/types';
import { IApplicationState } from '../store';
import { connect } from 'react-redux';
import { getCompanyImage } from '../api/fileServiceApi';
import { setLocalizationLanguage } from '../store/Localization/actions';
import { bindActionCreators, Dispatch } from 'redux';
import { setCurrentPage, setCurrentFlow } from '../store/Flow/actions';
import { LocalizationDictionary } from '../localization/localizationDictionary';
import { ILocalizationState } from '../store/Localization/types';
import CSS from 'csstype';
import { MyClock } from './MyClock/MyClock';
import { setLayoutData, setLayoutsRedirect, setLayoutsSharedHome, setConditionData, setDepartmentData } from '../store/LayoutData/actions';
import { setConfigData, setLanguageData } from '../store/ConfigData/actions';
import { setEmployees } from '../store/Employees/actions';
import { setCSolutionInOffice, setTMSASInOffice } from '../store/AccountValues/actions';
import { ILayoutDataState } from '../store/LayoutData/types';
import RedirectUtil from '../utils/RedirectUtil';
import UrlConfig from '../config/urlConfig';
import { IConfigDataState } from '../store/ConfigData/types';
import { getCompanyNameRequest } from '../api/checkinApi';
import DigitalClock from './LayoutElements/DigitalClock/DigitalClock';
import StylesUtil from '../utils/StylesUtil';
import ExtraButton from './LayoutElements/ExtraButton/ExtraButton';
import { getAllEmployeesRequest } from '../api/employeeApi';
import { initSignalR } from '../utils/signalRUtils';

initializeIcons();

interface IState {
    loading: boolean;
    logo: string,
    background: string,
}


interface IDispatchProps {
    setLocalizationLanguage: typeof setLocalizationLanguage;
    setCurrentPage: typeof setCurrentPage;
    setCurrentFlow: typeof setCurrentFlow;
    setLayoutData: typeof setLayoutData;
    setConfigData: typeof setConfigData;
    setLayoutsSharedHome: typeof setLayoutsSharedHome;
    setLayoutsRedirect: typeof setLayoutsRedirect;
    setConditionData: typeof setConditionData;
    setCSolutionInOffice: typeof setCSolutionInOffice;
    setTMSASInOffice: typeof setTMSASInOffice;
    setLanguageData: typeof setLanguageData;
    setDepartmentData: typeof setDepartmentData;
    setEmployees: typeof setEmployees;
}

type Props = IFlowState & IDispatchProps & ILocalizationState & ILayoutDataState & IConfigDataState;

class Home extends Component<Props, IState> {
    private readonly strings = LocalizationDictionary.getStrings;

    private currentPage1 = "";
    private firsttime = true;
    private count = 2;
    private homeButtonImage = '';
    private backgroundImage = "";

    public readonly state: IState = {
        loading: true,
        background: "",
        logo: "",
    }

    private loadConfig = async () => {
        const { setLocalizationLanguage, setLayoutData, setConfigData, setLayoutsSharedHome, setLayoutsRedirect, setConditionData, setLanguageData, setDepartmentData, setCSolutionInOffice, setTMSASInOffice, setEmployees } = this.props;

        let success = true;

        // Fetch layout data
        try {
            let companyId = await dispatchRequest(await getCompanyGuidFromId());  // TODO: Temporary untill we get validation
            let layout = await dispatchRequest(await getCheckInLayoutRequest(companyId));
            layout.companyId = companyId;
            setLayoutData(layout);
        }
        catch (e) { success = false; }

        // Fetch config data
        var getDepartments = false;
        let config: ClientConfigDTO;
        try {
            let companyId = await dispatchRequest(await getCompanyGuidFromId());  // TODO: Temporary untill we get validation
            config = await dispatchRequest(await getConfigRequest(companyId));

            // Improved
            let detailedConfig = await dispatchRequest(await getFullyDetailedConfigRequest(companyId));
            getDepartments = detailedConfig.useClientDepartments;
            setConfigData(detailedConfig);

            // Check for company name
            if (detailedConfig.name === "" || detailedConfig.name == null) {
                detailedConfig.name = await dispatchRequest(await getCompanyNameRequest(companyId));
            }

            // Language options
            let languageOptions = await dispatchRequest(await getLanguageOptionsRequest(companyId));
            setLanguageData(languageOptions);
        }
        catch (e) { success = false; }

        // Fetch optional data
        try {
            let companyId = await dispatchRequest(await getCompanyGuidFromId());  // TODO: Temporary untill we get validation

            let condition = await dispatchRequest(await getConditionRequest(companyId));
            setConditionData(condition);

            if (getDepartments) {
                let department = await dispatchRequest(await getDepartmentsRequest(companyId));
                setDepartmentData(department);
            }
        }
        catch (e) { success = false; }

        try {
            let csolutionInOffice = await dispatchRequest(await getAccountValue(154, "OfficeEmpty")); // TODO: Remove awful hack
            setCSolutionInOffice(csolutionInOffice);

            let tmsasInOffice = await dispatchRequest(await getAccountValue(168, "OfficeEmpty")); // TODO: Remove awful hack
            setTMSASInOffice(tmsasInOffice);
        }
        catch (e) { success = false; }

        // Set config (old way)
        if (config) {
            ClientConfig.flow.carrierFlowEnabled = config.courierFlowEnabled;
            ClientConfig.flow.doesNotHaveAppointmentEnabled = config.doesNotHaveAppointmentEnabled;
            ClientConfig.flow.informAllEnabled = config.informAllEnabled;
            ClientConfig.flow.checkoutEnabled = config.checkoutEnabled;

            ClientConfig.mainColor = config.mainColor;
            ClientConfig.secondaryColor = config.secondaryColor;
            ClientConfig.tertiaryColor = config.tertiaryColor;
            ClientConfig.language = config.defaultLanguage === 'DK' ? 'DK' : 'EN';

            //add if a new layout is made and if it has new start and timeout page set startpage here
            //(need a check in timeoutpage function to see version and set page acordingly, or add a )
            if (config.layoutVersion === 'v3') {
                this.currentPage1 = 'PausePage' as PageType;
                ClientConfig.layoutVersion = 'v3';
            } else if (config.layoutVersion === 'v2') {
                this.currentPage1 = 'FrontPage' as PageType;
                ClientConfig.layoutVersion = 'v2';
            } else if (config.layoutVersion === 'v1') {
                this.currentPage1 = 'FrontPage' as PageType;
                ClientConfig.layoutVersion = 'v1';
            }

            //console.log(config.layoutVersion + " Denne layout version")
            ClientConfig.logoId = config.logoId;
            ClientConfig.templateBackgroundId = config.templateBackgroundId;
            ClientConfig.mainTextColor = config.mainTextColor;
            ClientConfig.secondaryTextColor = config.secondaryTextColor;
            ClientConfig.tertiaryTextColor = config.tertiaryTextColor;
            ClientConfig.employeePhotoEnabled = config.employeePhotoEnabled;
            ClientConfig.employeeDepartmentEnabled = config.employeeDepartmentEnabled;
            ClientConfig.employeeJobTitlenabled = config.employeeJobTitlenabled;
            ClientConfig.employeePhoneNumberEnabled = config.employeePhoneNumberEnabled;

            await this.loadImages(config);

            var urlLanguage = new URLSearchParams(document.location.search).get('lang')
            if (urlLanguage)
                setLocalizationLanguage(urlLanguage)
            else
                setLocalizationLanguage(ClientConfig.language);
        } else {
            console.log("no config")
        }

        // Set up shared homes for layouts
        try {
            let sharedHomeConfig = new Map<string, string[]>()
            sharedHomeConfig.set("Visionhouse", ["Vision", "CSAM", "Risma", "Rovsing", "EssentialSecurity"])
            sharedHomeConfig.set("VismaHouse", ["economic", "INTEMPUS", "temponizer", "FirstAgenda", "plandisc", "upodi", "IMS", "sofus", "XFlow"])
            sharedHomeConfig.set("SSIDiagnosticaHouse", ["SSIDiagnostica", "ZigNaturTraining", "SmartPractice", "SolidConsult", "KimKnirkholtJensen", "JulieBerggren", "CoachSpirit"])
            sharedHomeConfig.set("CSolutionHouse", ["CSolution", "TMSAS", "BiggerBrand", "KMAutomation", "OneCode"])
            sharedHomeConfig.set("FibiaHaslevHouse", ["FibiaHaslev", "FibiaAndel", "FibiaMuseum"])
            sharedHomeConfig.set("LauritzenHouse", ["Lauritzen", "Bulkers"])
            sharedHomeConfig.set("DinexTMLHouse", ["Dinex", "TML"])

            var sharedHomeDTO = new CheckInSharedHomeDTO()
            sharedHomeDTO.config = sharedHomeConfig
            setLayoutsSharedHome(sharedHomeDTO)

            let redirectBaseUrl = await UrlConfig.getRedirectUrl();
            let redirectConfig = new Map<string, { url: string, layout: JSX.Element }>()
            redirectConfig.set("Vision", { url: RedirectUtil.getUrl("Vision", redirectBaseUrl), layout: RedirectUtil.getLayout("Vision", redirectBaseUrl) })
            redirectConfig.set("CSAM", { url: RedirectUtil.getUrl("CSAM", redirectBaseUrl), layout: RedirectUtil.getLayout("CSAM", redirectBaseUrl) })
            redirectConfig.set("Risma", { url: RedirectUtil.getUrl("Risma", redirectBaseUrl), layout: RedirectUtil.getLayout("Risma", redirectBaseUrl) })
            redirectConfig.set("Rovsing", { url: RedirectUtil.getUrl("Rovsing", redirectBaseUrl), layout: RedirectUtil.getLayout("Rovsing", redirectBaseUrl) })
            redirectConfig.set("EssentialSecurity", { url: RedirectUtil.getUrl("EssentialSecurity", redirectBaseUrl), layout: RedirectUtil.getLayout("EssentialSecurity", redirectBaseUrl) })

            redirectConfig.set("SSIDiagnostica", { url: RedirectUtil.getUrl("SSIDiagnostica", redirectBaseUrl), layout: RedirectUtil.getLayout("SSIDiagnostica", redirectBaseUrl) })
            redirectConfig.set("ZigNaturTraining", { url: RedirectUtil.getUrl("ZigNaturTraining", redirectBaseUrl), layout: RedirectUtil.getLayout("ZigNaturTraining", redirectBaseUrl) })
            redirectConfig.set("SmartPractice", { url: RedirectUtil.getUrl("SmartPractice", redirectBaseUrl), layout: RedirectUtil.getLayout("SmartPractice", redirectBaseUrl) })
            redirectConfig.set("SolidConsult", { url: RedirectUtil.getUrl("SolidConsult", redirectBaseUrl), layout: RedirectUtil.getLayout("SolidConsult", redirectBaseUrl) })
            redirectConfig.set("KimKnirkholtJensen", { url: RedirectUtil.getUrl("KimKnirkholtJensen", redirectBaseUrl), layout: RedirectUtil.getLayout("KimKnirkholtJensen", redirectBaseUrl) })
            redirectConfig.set("JulieBerggren", { url: RedirectUtil.getUrl("JulieBerggren", redirectBaseUrl), layout: RedirectUtil.getLayout("JulieBerggren", redirectBaseUrl) })
            redirectConfig.set("CoachSpirit", { url: RedirectUtil.getUrl("CoachSpirit", redirectBaseUrl), layout: RedirectUtil.getLayout("CoachSpirit", redirectBaseUrl) })

            redirectConfig.set("CSolution", { url: RedirectUtil.getUrl("CSolution", redirectBaseUrl), layout: RedirectUtil.getLayout("CSolution", redirectBaseUrl) })
            redirectConfig.set("TMSAS", { url: RedirectUtil.getUrl("TMSAS", redirectBaseUrl), layout: RedirectUtil.getLayout("TMSAS", redirectBaseUrl) })
            redirectConfig.set("BiggerBrand", { url: RedirectUtil.getUrl("BiggerBrand", redirectBaseUrl), layout: RedirectUtil.getLayout("BiggerBrand", redirectBaseUrl) })
            redirectConfig.set("KMAutomation", { url: RedirectUtil.getUrl("KMAutomation", redirectBaseUrl), layout: RedirectUtil.getLayout("KMAutomation", redirectBaseUrl) })
            redirectConfig.set("OneCode", { url: RedirectUtil.getUrl("OneCode", redirectBaseUrl), layout: RedirectUtil.getLayout("OneCode", redirectBaseUrl) })

            redirectConfig.set("FibiaHaslev", { url: RedirectUtil.getUrl("FibiaHaslev", redirectBaseUrl), layout: RedirectUtil.getLayout("FibiaHaslev", redirectBaseUrl) })
            redirectConfig.set("FibiaAndel", { url: RedirectUtil.getUrl("FibiaAndel", redirectBaseUrl), layout: RedirectUtil.getLayout("FibiaAndel", redirectBaseUrl) })
            redirectConfig.set("FibiaMuseum", { url: RedirectUtil.getUrl("FibiaMuseum", redirectBaseUrl), layout: RedirectUtil.getLayout("FibiaMuseum", redirectBaseUrl) })

            redirectConfig.set("Lauritzen", { url: RedirectUtil.getUrl("Lauritzen", redirectBaseUrl), layout: RedirectUtil.getLayout("Lauritzen", redirectBaseUrl) })
            redirectConfig.set("Bulkers", { url: RedirectUtil.getUrl("Bulkers", redirectBaseUrl), layout: RedirectUtil.getLayout("Bulkers", redirectBaseUrl) })

            redirectConfig.set("economic", { url: RedirectUtil.getUrl("economic", redirectBaseUrl), layout: RedirectUtil.getLayout("economic", redirectBaseUrl) })
            redirectConfig.set("INTEMPUS", { url: RedirectUtil.getUrl("INTEMPUS", redirectBaseUrl), layout: RedirectUtil.getLayout("INTEMPUS", redirectBaseUrl) })
            redirectConfig.set("temponizer", { url: RedirectUtil.getUrl("temponizer", redirectBaseUrl), layout: RedirectUtil.getLayout("temponizer", redirectBaseUrl) })
            redirectConfig.set("FirstAgenda", { url: RedirectUtil.getUrl("FirstAgenda", redirectBaseUrl), layout: RedirectUtil.getLayout("FirstAgenda", redirectBaseUrl) })
            redirectConfig.set("plandisc", { url: RedirectUtil.getUrl("plandisc", redirectBaseUrl), layout: RedirectUtil.getLayout("plandisc", redirectBaseUrl) })
            redirectConfig.set("upodi", { url: RedirectUtil.getUrl("upodi", redirectBaseUrl), layout: RedirectUtil.getLayout("upodi", redirectBaseUrl) })
            redirectConfig.set("IMS", { url: RedirectUtil.getUrl("IMS", redirectBaseUrl), layout: RedirectUtil.getLayout("IMS", redirectBaseUrl) })
            redirectConfig.set("sofus", { url: RedirectUtil.getUrl("sofus", redirectBaseUrl), layout: RedirectUtil.getLayout("sofus", redirectBaseUrl) })
            redirectConfig.set("XFlow", { url: RedirectUtil.getUrl("XFlow", redirectBaseUrl), layout: RedirectUtil.getLayout("XFlow", redirectBaseUrl) })
            redirectConfig.set("Dinex", { url: RedirectUtil.getUrl("Dinex", redirectBaseUrl), layout: RedirectUtil.getLayout("Dinex", redirectBaseUrl) })
            redirectConfig.set("TML", { url: RedirectUtil.getUrl("TML", redirectBaseUrl), layout: RedirectUtil.getLayout("TML", redirectBaseUrl) })

            var redirectHomeDTO = new CheckInRedirectHomeDTO()
            redirectHomeDTO.config = redirectConfig
            setLayoutsRedirect(redirectHomeDTO)
        }
        catch (e) { success = false; }

        // Fetch employees
        try {
            let companyId = await dispatchRequest(await getCompanyGuidFromId()); // TODO: Temporary untill we get validation
            var employees: EmployeeDTO[] = [];
            let clients = (await dispatchRequest(await getAllEmployeesRequest(companyId))) as ClientDTO[];

            clients = this.sortClientsByName(clients)

            clients.forEach((item) => {
                employees.push(
                    {
                        id: item.id ?? 0,
                        firstName: item.name.substring(0, item.name.indexOf(' ')) ?? "",
                        secondName: item.name.substring(item.name.indexOf(' ') + 1) ?? "",
                        department: item.department ?? "",
                        jobTitle: item.jobTitle ?? "",
                        phoneNumber: item.phoneNumber ?? "",
                        imageId: item.image?.id ?? 0,
                        image: item.image?.file ?? "",
                        emailNotification: item.emailNotification ?? false,
                        smsNotification: item.smsNotification ?? false
                    }
                );
            });
            setEmployees(employees);
        }
        catch (e) { success = false; }

        // Succeeds if we managed to fetch layout and config data
        if (success) {
            this.setState({ loading: false });
        }
    }

    sortClientsByName(clients: ClientDTO[]): ClientDTO[] {
        return clients.sort((a, b) => a.name.localeCompare(b.name))
    }

    loadImages = async (config: ClientConfigDTO) => {
        const { layoutData } = this.props

        if (config?.logoId !== 0) {
            const logo = await this.getCompanyImage(config.logoId);
            this.setState({ logo })
        }

        if (config?.templateBackgroundId !== 0) {
            const background = await this.getCompanyImage(config.templateBackgroundId);
            this.setState({ background });

            if (layoutData.code === "OfficePartner") {
                this.backgroundImage = background;
            }


        }
    }

    private getCompanyImage = async (imageId: number) => {
        try {
            let image = (await dispatchRequest(await getCompanyImage(imageId))) as ImageDTO;
            return image.file;
        }
        catch {
            return ""
        }
    }

    private updateBodyStyle(layoutData: CheckInLayoutDTO) {
        if (layoutData.code === "Dragsbaek") {
            document.body.style.width = '100%'
            document.body.style.height = '100%'
            document.body.style.overflowX = 'hidden'
            document.body.style.overflowY = 'auto'
        } else {
            document.body.style.width = '1920px'
            document.body.style.height = '1080px'
            document.body.style.overflow = 'hidden'
            document.body.style.background = 'transparent'
        }
        if (window.location.toString().includes('localhost'))
            document.body.style.background = 'grey'
    }

    public componentDidMount = async () => {
        await this.loadConfig();
        setInterval(this.loadConfig, 15 * 60 * 1000);

        const { layoutData, configData } = this.props;
        initSignalR(configData);

        this.updateBodyStyle(layoutData);

        // Set global font
        var backgroundImage;
        if (layoutData.code === "Kjaergaard") {
            backgroundImage = document.getElementsByClassName("ultra-turbo-container") as HTMLCollectionOf<HTMLElement>
            backgroundImage[0].classList.add("font-frankling");
        } else if (layoutData.code === "Dragsbaek") {
            backgroundImage = document.getElementsByClassName("PhoneFriendly-ultra-turbo-container") as HTMLCollectionOf<HTMLElement>
            backgroundImage[0].classList.add("font-montserrat");
        } else if (layoutData.code === "Lauritzen" || layoutData.code === "LauritzenHouse" || layoutData.code === "Bulkers") {
            backgroundImage = document.getElementsByClassName("ultra-turbo-container") as HTMLCollectionOf<HTMLElement>
            backgroundImage[0].classList.add("font-calibri");
        } else {
            backgroundImage = document.getElementsByClassName("ultra-turbo-container") as HTMLCollectionOf<HTMLElement>
            backgroundImage[0].classList.add("font-montserrat");
        }

        // Adjust home layout
        var portrait;
        var clock;
        var clockd;
        var clockc;
        if (layoutData.code === "OfficePartner") {
            portrait = document.getElementById("root");
            portrait.style.width = '1080px';
            portrait.style.height = '1920px';
        } else if (layoutData.code === "Fundamentet" || layoutData.code === "BirchEjendomme") {
            var body = document.getElementsByTagName("body")[0];
            body.style.height = '100%'
            body.style.width = '100%'

            backgroundImage = document.getElementsByClassName("ultra-turbo-container") as HTMLCollectionOf<HTMLElement>

            backgroundImage[0].style.height = '100vh'
            backgroundImage[0].style.backgroundSize = '100% 100%'

            portrait = document.getElementById("root");
            portrait.style.width = '100%';
            portrait.style.height = '100%';
        } else if (layoutData.code === "ErhvershusMidt") {
            clock = document.getElementsByClassName("clock-time") as HTMLCollectionOf<HTMLElement>
            clock[0].style.color = 'white'
            clockd = document.getElementsByClassName("clock-date") as HTMLCollectionOf<HTMLElement>
            clockd[0].style.color = 'white'
        } else if (layoutData.code === "Kjaergaard") {
            clockc = document.getElementsByClassName("clock-container") as HTMLCollectionOf<HTMLElement>
            clockc[0].style.alignItems = 'flex-end'
            clock = document.getElementsByClassName("clock-time") as HTMLCollectionOf<HTMLElement>
            clock[0].style.fontSize = '30px'
            clock[0].style.fontWeight = 'bold'
            clockd = document.getElementsByClassName("clock-date") as HTMLCollectionOf<HTMLElement>
            clockd[0].style.fontSize = '30px'
            clockd[0].style.fontWeight = 'bold'
        }
    }

    public componentDidUpdate = async (snapshot: any) => {
        const { currentPage } = this.props;
        const { layoutData } = snapshot;

        if (currentPage !== 'FrontPage' && layoutData.code === "OfficePartner") {
            const languagePicker = document.getElementById("LanguagePicker");
            languagePicker.style.top = '2%'
            languagePicker.style.left = '2%'
        } else if (layoutData.code === "OfficePartner") {
            try {
                const languagePicker = document.getElementById("LanguagePicker");
                languagePicker.style.top = '60%'
                languagePicker.style.left = '40.5%'
            } catch (e) {

            }

        }

        try {
            if (layoutData.code === "OfficePartner" && currentPage === 'FrontPage') {

                const background = document.getElementsByClassName("ultra-turbo-container") as HTMLCollectionOf<HTMLElement>;
                //console.log("component update run : " + background[0].style.backgroundImage);
                background[0].style.backgroundImage = `url(${this.backgroundImage})`;
            }
        } catch (e) {
            //console.log(background);

        }


        try {
            new MyClock(document.getElementById('clock'))
        } catch (e) {
            //console.log(e);
        }
    }


    //does not change the start of the screen but when it goes in pause it returns
    //change handleOnIdle in contentFlowPage if a new layout is added
    //todo future add so the page is set base on layout version
    public changeTimeoutPage(page: PageType) {
        const { currentPage } = this.props;

        if (currentPage === page) {
            if (this.count % 2 === 0 && this.count >= 0) {
                //console.log("if statement og count er: " + this.count);
                this.currentPage1 = page;
                this.count = this.count + 2;
            }
        }
    }


    public LanguageAllowed() {
        const { layoutData } = this.props

        if (layoutData.code === "SociatyOfLife" || layoutData.code === "Retograad") {

        } else {
            return (<LanguagePicker />);
        }
    }

    public addClock() {
        const { layoutData } = this.props

        if (layoutData.code === "SociatyOfLife" || layoutData.code === "Retograad") {

        } else {
            return (<DigitalClock />)
        }
    }

    public addMyClock() {
        const { layoutData } = this.props

        if (layoutData.code === "Retograad") {
            return (<div style={this.styleMyClock()} id="clock"></div>)
        }
    }

    private styleMyClock(): CSS.Properties {
        return {
            position: 'absolute', right: '10px', top: '101px', fontSize: '23px', fontFamily: 'Calibri'
        }
    }

    private getTitleWithStyle() {
        const { layoutData } = this.props

        if (layoutData.code === "SociatyOfLife") {
            return (<h2 style={{ fontSize: '145px', color: ClientConfig.secondaryTextColor, height: '1px', verticalAlign: 'bottom', fontFamily: 'Times New Roman, Times, Serif', letterSpacing: '5px', fontWeight: 1 }}>Welcome</h2>)
        }
    }

    private getTouchScreenStyle = (): CSS.Properties => {
        const { layoutData } = this.props

        if (layoutData.code === "Retograad") {
            return {
                fontSize: '80px',
                color: ClientConfig.mainTextColor,
                position: 'absolute',
                left: '270px',
                top: '700px'

            };
        } else {
            return {
                fontSize: '40px',
                color: ClientConfig.secondaryTextColor,
                height: '6px',
                verticalAlign: 'top'
            };
        }

    }

    //v3 smaller header
    // TODO: Special logo case
    private addHeaderDiv = (logo: string): any => {
        const { layoutData } = this.props

        if (layoutData.code === "SociatyOfLife" || layoutData.code === "Retograad") {
            return (<div style={StylesUtil.getHeaderStyle(layoutData)}> {this.addMyClock()} {this.addClock()} </div>)
        } else {
            return (<div style={StylesUtil.getHeaderStyle(layoutData)}> <img className={"logo-" + ClientConfig.layoutVersion} src={logo} alt="Logo" /> {this.addClock()} </div>)
        }
    }


    public getPauseScreenText() {
        const { layoutData } = this.props

        var TouchScreenText;
        if (layoutData.code === "SociatyOfLife") {
            TouchScreenText = "Tap Screen to check in"
            return (TouchScreenText)
        } else if (layoutData.code === "Retograad") {
            TouchScreenText = "Registrer din ankomst her"
            return (TouchScreenText)

        } else {
            return (this.strings().touchTheScreen)
        }
    }

    // TODO: Special logo case
    public centerLogo(logo: string) {
        const { setCurrentPage, setCurrentFlow, layoutData } = this.props;

        if (layoutData.code === "SociatyOfLife") {
            return (<img className={`front-logo`} style={{ marginLeft: '576px', marginRight: '576px', width: '768px', position: 'absolute', left: '0px', top: '800px' }} onClick={() => {
                this.currentPage1 = 'FrontPage' as PageType
                setCurrentPage("FrontPage" as PageType);
                setCurrentFlow("CheckinAppointment" as FlowType);
            }} src={logo} alt="Sociaty of life front logo" />)
        } else if (layoutData.code === "Retograad") {
            return (<img className={`front-logo`} style={{ position: 'absolute', left: '1300px', top: '550px' }} src={logo} onClick={() => {
                this.currentPage1 = 'FrontPage' as PageType
                setCurrentPage("FrontPage" as PageType);
                setCurrentFlow("CheckinAppointment" as FlowType);
            }} alt="Retograad front logo" />)
        } else {
            return (<img className={`front-logo`} style={{ marginLeft: '442px', marginRight: '576px', width: '768px' }} src={logo} onClick={() => {
                this.currentPage1 = 'FrontPage' as PageType
                setCurrentPage("FrontPage" as PageType);
                setCurrentFlow("CheckinAppointment" as FlowType);
            }} alt="Front logo" />)
        }
    }


    private v2Click() {
        const { setCurrentPage, setCurrentFlow, layoutData } = this.props;
        if (layoutData.code === "Fundamentet") {
            this.currentPage1 = 'MainFieldsPage' as PageType
            setCurrentPage("MainFieldsPage" as PageType);
            setCurrentFlow("CheckinAppointment" as FlowType);

        } else {
            this.currentPage1 = 'PhoneNumberPage' as PageType
            setCurrentPage("PhoneNumberPage" as PageType);
            setCurrentFlow("CheckinAppointment" as FlowType);
        }
        //console.log("does it click ?")
    }

    private styleBackground(image: any) { // TODO: Should probably be replaced with addHeaderStyle() & addBackgroundStyle()?
        const { layoutData } = this.props

        if (layoutData.code === "SociatyOfLife") {
            return { backgroundColor: ClientConfig.secondaryColor }
        } else {
            return { backgroundImage: image }
        }
    }

    private returnClick = () => {
        const { setCurrentPage, layoutData } = this.props;
        const { background } = this.state;

        var background1;
        if (layoutData.code === "Dragsbaek") {
            background1 = document.getElementsByClassName("PhoneFriendly-ultra-turbo-container") as HTMLCollectionOf<HTMLElement>;
        } else {
            background1 = document.getElementsByClassName("ultra-turbo-container") as HTMLCollectionOf<HTMLElement>;
        }

        if (background) {
            background1[0].style.backgroundImage = `url(${background})`;
        } else {
            background1[0].style.backgroundImage = `none`;
        }

        setCurrentPage("FrontPage" as PageType);
    }

    private returnStyle(): React.CSSProperties {
        return {
            fontSize: "28px",
            height: "55px",
            width: "160px",
            color: ClientConfig.secondaryTextColor,
            backgroundColor: ClientConfig.mainColor,
            position: 'absolute',
            bottom: 0,
            left: 0,
            textAlign: 'center',
            paddingTop: '5px',
            fontWeight: 'bold'
        }
    }

    private addHomeButton() {
        const { currentPage, layoutData } = this.props;
        if (ClientConfig.layoutVersion === 'v3' && currentPage !== 'FrontPage' && layoutData.code !== "Retograad") {
            return (<div style={this.returnStyle()} onClick={this.returnClick}>Home</div>)
        }
    }


    private pausePageStyle() {
        const { layoutData } = this.props

        if (layoutData.code === "Retograad") {
            return (<div id='WhiteScreenSpace' style={{ backgroundColor: ClientConfig.secondaryColor, height: '585px', width: '1860px', position: 'absolute', top: '465px' }}></div>)
        }
    }

    private styleLanguage(): React.CSSProperties {
        const { layoutData } = this.props

        if (layoutData.code === "OfficePartner") {

            return { position: 'absolute', left: '40.5%', top: '60%' }
        } else if (layoutData.code === "EDForhandler") {
            return { position: 'absolute', left: '0%', top: '0%' }
        } else if (layoutData.code === "Haugen") {
            return { borderRadius: '0' }
        }
    }




    private AddLaunguagueAndHomeButtonV1(currentPage: any) {
        const { layoutData } = this.props

        if (layoutData.code === "OfficePartner" || layoutData.code === "EDForhandler") {
            return (
                <div style={StylesUtil.getFooterStyle(layoutData)}>
                    <div id="LanguagePicker" style={this.styleLanguage()}> <LanguagePicker /> </div>
                    {ClientConfig.layoutVersion === 'v1' &&
                        <>
                            {currentPage === 'FrontPage' ?
                                <div /> :
                                <div style={StylesUtil.getHomeContainerStyle(layoutData)} className="home-button-container">
                                    <HomeButton />
                                </div>
                            }
                        </>
                    }
                </div>)
        } else if (layoutData.code === "Reisswolf") {
            return (
                <div style={StylesUtil.getFooterStyle(layoutData)}>
                    {ClientConfig.layoutVersion === 'v1' &&
                        <>
                            {currentPage === 'FrontPage' ?
                                <LanguagePicker /> :
                                <div></div>
                            }
                        </>
                    }
                </div>)

        } else if (layoutData.code === "Haugen") {
            return (
                <div style={StylesUtil.getFooterStyle(layoutData)}>
                    {ClientConfig.layoutVersion === 'v1' &&
                        <>
                            {currentPage === 'FrontPage' ?
                                <LanguagePicker /> :
                                <div style={StylesUtil.getHomeContainerStyle(layoutData)} className="home-button-container">
                                    <HomeButton />
                                </div>
                            }
                        </>
                    }
                </div>)

        } else if (layoutData.code === "Lauritzen" || layoutData.code === "Bulkers") {
            return (
                <div style={StylesUtil.getFooterStyle(layoutData)}>
                    <>
                        <div style={StylesUtil.getHomeContainerStyle(layoutData)} className="home-button-container">
                            <HomeButton />
                        </div>
                    </>
                </div>)

        } else {
            return (
                <div style={StylesUtil.getFooterStyle(layoutData)}>
                    {ClientConfig.layoutVersion === 'v1' &&
                        <>
                            {currentPage === 'FrontPage' ?
                                <LanguagePicker /> :
                                <div style={StylesUtil.getHomeContainerStyle(layoutData)} className="home-button-container">
                                    <HomeButton />
                                </div>
                            }
                        </>
                    }
                </div>)
        }
    }

    // TODO: Standard logo case
    private addLogoV1(logo: any) {
        const { currentPage, layoutData } = this.props

        if (logo === "" || layoutData.code === "OfficePartner" || layoutData.code === "Reisswolf" || (layoutData.code === "Kjaergaard" && (currentPage === 'MessagePage' || currentPage === 'FrontPage')) || ((layoutData.code === "Flensted" || layoutData.code === "FlenstedWorkshop") && currentPage !== 'FrontPage')) {
            return (<div></div>)
        } else {
            return (<img style={StylesUtil.getLogoStyle(layoutData)} src={logo} alt="Logo" />)
        }
    }

    private closeFrame() {
        var frame = document.getElementById('frame')
        frame.style.display = 'none'
    }

    private addPopupBox() {
        const { layoutData } = this.props

        if (layoutData.code === "Seftest" || layoutData.code === "Sef") {
            return (<div style={{ position: 'absolute', left: 20000, zIndex: 1, width: 1920, height: 1080, backgroundColor: "rgba(0, 0, 0, .5)" }} id="frame"><div onClick={() => this.closeFrame()} style={{ width: 50, height: 50, backgroundColor: 'red', color: 'white', borderRadius: 25, position: 'absolute', top: 135, right: 335, fontSize: 70, transform: 'rotate(45deg)', textAlign: 'center', lineHeight: 0.7, zIndex: 2 }}>+</div> <iframe src="https://websites.app-infotv.net/sites/sef/sites/sef.html" style={{ position: 'absolute', width: 1220, height: 780, overflow: 'scroll', left: 350, top: 150 }} title="SEF link" /></div>)
        }
    }

    addBackgroundStyle(): React.CSSProperties {
        const { background } = this.state;
        const { currentPage, layoutData } = this.props

        if (layoutData.code === "Kjaergaard") {
            if (currentPage !== 'MessagePage' && currentPage !== 'FrontPage') {
                return ({ backgroundImage: `none` })
            }
        }

        if (background) {
            return ({ backgroundImage: `url(${background})` })
        } else {
            return ({ backgroundImage: `none` })
        }
    }

    render() {
        const { loading, logo, background } = this.state;
        const { currentPage, setCurrentPage, setCurrentFlow, layoutData } = this.props;
        this.homeButtonImage = background

        this.changeTimeoutPage('PausePage')

        if (loading) {
            return (<div>Loading...</div>);
        }

        if (layoutData.code === "Dragsbaek") {
            return (
                <Fabric className="PhoneFriendly-ultra-turbo-container" style={this.addBackgroundStyle()}>
                    <div style={StylesUtil.getHeaderStyle(layoutData)}>
                        {this.addLogoV1(logo)}
                        <DigitalClock />
                        {this.addPopupBox()}
                    </div>
                    <ContentFlowPage />
                    <div style={StylesUtil.getFooterParentStyle(layoutData)}>
                        {this.AddLaunguagueAndHomeButtonV1(currentPage)}
                        <ExtraButton />
                    </div>
                </Fabric>
            );
        } else if (this.currentPage1 === 'FrontPage' && (layoutData.code === "Lauritzen" || layoutData.code === "Bulkers" || layoutData.code === "LauritzenHouse")) {
            return (
                <Fabric className="ultra-turbo-container" style={this.addBackgroundStyle()}>
                    <div style={StylesUtil.getHeaderStyle(layoutData)}>
                        {this.addLogoV1(logo)}
                        <ExtraButton />
                        {this.addPopupBox()}
                    </div>
                    <ContentFlowPage />
                    <div style={StylesUtil.getFooterParentStyle(layoutData)}>
                        {this.AddLaunguagueAndHomeButtonV1(currentPage)}
                        <DigitalClock />
                    </div>
                </Fabric>
            );
        } else if (this.currentPage1 === 'FrontPage' && (layoutData.code === "Haugen")) {
            return (
                <Fabric className="ultra-turbo-container" style={this.addBackgroundStyle()}>
                    <div style={StylesUtil.getHeaderStyle(layoutData)}>
                        <ExtraButton />
                        {this.addPopupBox()}
                    </div>
                    <ContentFlowPage />
                    <div style={StylesUtil.getFooterParentStyle(layoutData)}>
                        {this.AddLaunguagueAndHomeButtonV1(currentPage)}
                        {this.addLogoV1(logo)}
                    </div>
                </Fabric>
            );
        } else if (this.currentPage1 === 'FrontPage' && ClientConfig.layoutVersion === 'v1') { // TODO: What is currentPage1? Seems useless
            return (
                <Fabric className="ultra-turbo-container" style={this.addBackgroundStyle()}>
                    <div style={StylesUtil.getHeaderStyle(layoutData)}>
                        {this.addLogoV1(logo)}
                        <DigitalClock />
                        {this.addPopupBox()}
                    </div>
                    <ContentFlowPage />
                    <div style={StylesUtil.getFooterParentStyle(layoutData)}>
                        {this.AddLaunguagueAndHomeButtonV1(currentPage)}
                        <ExtraButton />
                    </div>
                </Fabric>
            );
        } else if (currentPage === 'FrontPage' && ClientConfig.layoutVersion === 'v2') {
            //console.log("den hopper i v2")

            // TODO: Special logo case
            return (
                <Fabric className="ultra-turbo-container" style={this.addBackgroundStyle()} >
                    <div style={StylesUtil.getHeaderStyle(layoutData)}>
                        <LanguagePicker />
                    </div>
                    <div className="front-logo-container"
                        onClick={() => {
                            this.v2Click()
                        }}>
                        <div>
                            <img className={`front-logo`} src={logo} alt="Front logo" />
                            <h2>{this.strings().touchTheScreen}</h2>
                        </div>
                    </div>
                    <div style={StylesUtil.getFooterStyle(layoutData)}>
                        <DigitalClock />
                    </div>
                </Fabric>
            );
        } else if (this.currentPage1 === 'PausePage' && ClientConfig.layoutVersion === 'v3') {
            //console.log("den hopper i v3")

            //tilf�j home knappen til layout 3
            return (
                <Fabric className="ultra-turbo-container" style={this.styleBackground(`url(${background})`)} >
                    <div style={StylesUtil.getHeaderStyle(layoutData)}>
                        {this.LanguageAllowed()}
                        {this.addClock()}
                        {this.addMyClock()}
                    </div>
                    <div className="front-logo-container"
                        onClick={() => {
                            this.currentPage1 = 'FrontPage' as PageType
                            setCurrentPage("FrontPage" as PageType);
                            setCurrentFlow("CheckinAppointment" as FlowType);

                        }}>
                        <div style={{ height: '550px' }}>
                            {this.pausePageStyle()}
                            {this.getTitleWithStyle()}
                            <h2 style={this.getTouchScreenStyle()}>{this.getPauseScreenText()}</h2>
                        </div>
                    </div>
                    <div style={StylesUtil.getFooterStyle(layoutData)}>


                        {this.centerLogo(logo)}
                    </div>
                </Fabric>

            );

        } else {
            //console.log("den hopper i else")

            // TODO: Special logo case
            return (
                //doesnt seem to work by adding version 3 
                (this.currentPage1 === 'FrontPage' && ClientConfig.layoutVersion === 'v2') ?
                    <Fabric className="ultra-turbo-container" style={this.addBackgroundStyle()} >
                        <div style={StylesUtil.getHeaderStyle(layoutData)}>
                            <LanguagePicker />
                        </div>
                        <div className="front-logo-container"
                            onClick={() => {
                                setCurrentPage("PhoneNumberPage" as PageType);
                                setCurrentFlow("CheckinAppointment" as FlowType);
                            }}>
                            <div>
                                <img className={`front-logo`} src={logo} alt="Front logo" />
                                <h2>{this.strings().touchTheScreen}</h2>
                            </div>
                        </div>
                        <div style={StylesUtil.getFooterStyle(layoutData)}>
                            <DigitalClock />
                        </div>
                    </Fabric>
                    : <Fabric className="ultra-turbo-container" style={this.addBackgroundStyle()}>
                        {this.addHeaderDiv(logo)}
                        {this.addHomeButton()}
                        <ContentFlowPage />
                        <div style={StylesUtil.getFooterStyle(layoutData)}>

                        </div>
                    </Fabric>

            );

        }
    }
}

const mapStateToProps = (state: IApplicationState): Partial<IFlowState & ILocalizationState & ILayoutDataState & IConfigDataState> => {
    const { currentPage, currentFlow } = state.flow;
    const { language } = state.localization
    const { layoutData, conditionData, departmentsData } = state.layoutData;
    const { configData } = state.configData;

    return {
        currentPage, currentFlow, language, layoutData, configData, conditionData, departmentsData
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return bindActionCreators(
        { setLocalizationLanguage, setCurrentPage, setCurrentFlow, setLayoutData, setConfigData, setLayoutsSharedHome, setLayoutsRedirect, setConditionData, setDepartmentData, setLanguageData, setCSolutionInOffice, setTMSASInOffice, setEmployees },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);

// DTO interface implementations
class CheckInRedirectHomeDTO {
    config: Map<string, { url: string, layout: JSX.Element }>;
}
class CheckInSharedHomeDTO {
    config: Map<string, string[]>;
}