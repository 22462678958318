import { FontIcon } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';

import { LocalizationDictionary, LocalizationKey } from '../../../localization/localizationDictionary'
import { IApplicationState } from '../../../store';
import { IConfigDataState } from '../../../store/ConfigData/types';
import { ILayoutDataState } from '../../../store/LayoutData/types';
import { ILocalizationState } from '../../../store/Localization/types';
import StylesUtil from '../../../utils/StylesUtil';

import './index.css'

type CompId = number;
type KeyType = number | string;
type CardType = "Appointment" | "NoAppointment" | "Carrier" | "Checkout" | "HR" | "Other" | "CheckinNoInfo" | "Behandling" | "Fællesskab" | "Mentorsamtale" | "Praktiskhjælp" | "HF" | "Frivilig" | "GroupCheckin" | "LeverandoerCheckin" | "Event" | "Carpenter" | "Applicant" | "Guest" | "HotWork" | "NotHotWork" | "ExternalService";

interface IProps {
    cardKey: KeyType;
    click: (key: KeyType, purpose: string) => void;
    type: CardType;
    companyId?: CompId;
}

type Props = IProps & ILocalizationState & ILayoutDataState & IConfigDataState;

class FlowCard extends React.Component<Props> {

    private readonly strings = LocalizationDictionary.getStrings;

    private getIconPath = (): string => {
        const { type, layoutData } = this.props;

        switch (type) {
            case "Appointment":
                if (layoutData.code === "Kjaergaard") {
                    return window.location.origin + "/images/HarAftale1.png";
                } else if (layoutData.code === "DSStaal") {
                    return window.location.origin + "/images/DSStaal/meeting.png"
                } else {
                    return window.location.origin + "/images/person.png";
                }

            case "NoAppointment":
                if (layoutData.code === "ErhvershusMidt") {
                    return window.location.origin + "/images/calx.png";
                } else if (layoutData.code === "DSStaal") {
                    return window.location.origin + "/images/DSStaal/meeting.png"
                } else if (layoutData.code === "Kjaergaard") {
                    return window.location.origin + "/images/HarIkkeAftale1.png";
                } else {
                    return window.location.origin + "/images/person.png";
                }
            case "Carrier":
                if (layoutData.code === "JKF") {
                    return window.location.origin + "/images/icon delivery.jpg";
                } else if (layoutData.code === "Kilde") {
                    return window.location.origin + "/images/Fragtmand-lastbil.png";
                } else {
                    return window.location.origin + "/images/person.png";
                }
            case "Checkout":
                if (layoutData.code === "Kjaergaard") {
                    return window.location.origin + "/images/CheckUd1.png";
                } else if (layoutData.code === "DSStaal") {
                    return window.location.origin + "/images/DSStaal/check_out.png"
                } else {
                    return window.location.origin + "/images/person.png";
                }
            case "CheckinNoInfo":
                if (layoutData.code === "ErhvershusMidt") {
                    return window.location.origin + "/images/calcheck.png"
                } else {
                    return window.location.origin + "/images/person.png";
                }
            case "Carpenter":
                if (layoutData.code === "DSStaal") {
                    return window.location.origin + "/images/DSStaal/craftsman.png"
                } else {
                    return window.location.origin + "/images/person.png";
                }
            case "ExternalService":
                if (layoutData.code === "DSStaal") {
                    return window.location.origin + "/images/DSStaal/service.png"
                } else {
                    return window.location.origin + "/images/service.png";
                }
            default:
                return "";
        }
    }


    private getText = (): string => {
        const { type, languageData, language } = this.props;
        var currentLanguageOptions = languageData.find(({ localizationCode }) => localizationCode === language);

        switch (type) {
            case "Appointment":
                if (currentLanguageOptions && currentLanguageOptions.appointmentButton !== null && currentLanguageOptions.appointmentButton.trim() !== "") {
                    return currentLanguageOptions.appointmentButton;
                }
                return this.strings()["appointment"];
            case "NoAppointment":
                if (currentLanguageOptions && currentLanguageOptions.doesNotHaveAnAppointmentButton !== null && currentLanguageOptions.doesNotHaveAnAppointmentButton.trim() !== "") {
                    return currentLanguageOptions.doesNotHaveAnAppointmentButton;
                }
                return this.strings()["noAppointment"];
            case "Carrier":
                if (currentLanguageOptions && currentLanguageOptions.courierButton !== null && currentLanguageOptions.courierButton.trim() !== "") {
                    return currentLanguageOptions.courierButton;
                }
                return this.strings()["carrier"];
            case "Checkout":
                if (currentLanguageOptions && currentLanguageOptions.checkoutButton !== null && currentLanguageOptions.checkoutButton.trim() !== "") {
                    return currentLanguageOptions.checkoutButton;
                }
                return this.strings()["checkout"];
            case "HR":
                return this.strings()["hr"];
            case "Other":
                return this.strings()["other"];
            case "CheckinNoInfo":
                return this.strings()["checkinNoInfo"];
            case "Behandling":
                return this.strings()["behandling"];
            case "Fællesskab":
                return this.strings()["fællesskab"];
            case "Mentorsamtale":
                return this.strings()["mentorsamtale"];
            case "Praktiskhjælp":
                return this.strings()["praktikshjælp"];
            case "HF":
                return this.strings()["hf"];
            case "Frivilig":
                return this.strings()["frivillig"];
            case "GroupCheckin":
                return this.strings()["groupcheckin"];
            case "LeverandoerCheckin":
                return this.strings()["levenrandoer"];
            case "Event":
                return this.strings()["EDevent"];
            case "Carpenter":
                return this.strings()["carpenter"];
            case "Applicant":
                return this.strings()["applicant"];
            case "Guest":
                return this.strings()["guest"];
            case "HotWork":
                return this.strings()["HotWork"];
            case "NotHotWork":
                return this.strings()["NotHotWork"];
            case "ExternalService":
                return this.strings()["externalServiceButton"];
            default:
                return this.strings()["appointment"];
        }
    }

    private getModifiedText = (): string => {
        const { layoutData, type } = this.props;

        switch (type) {
            case "Carrier":
                if (layoutData.code === "OestjyllandsBrandvæsen" && this.strings()["carrier"] === "Fragtmand") {
                    return "Pakkepost/ fragtmænd";
                } else if (layoutData.code === "SociatyOfLife" && this.strings()["carrier"] === "I'm a courier") {
                    return "Package delivery";
                }
                break;
            case "Appointment":
                if (layoutData.code === "OfficePartner") {
                    if (this.strings()["language"] === "EN") {
                        return "Dear guest please register here.";
                    } else {
                        return "Kære gæst venligst registrér dig her.";
                    }
                } else if (layoutData.code === "EDForhandler") {
                    return this.strings().EDappointment;
                } else if (layoutData.code === "Reisswolf") {
                    return this.strings().checkinreisswolf
                } else if (layoutData.code === "Kjaergaard") {
                    return this.getText().toUpperCase();
                }
                break;
            case "NoAppointment":
                if (layoutData.code === "EDForhandler") {
                    return this.strings().EDnoappointment;
                } else if (layoutData.code === "Visionhouse" || layoutData.code === "VismaHouse" || layoutData.code === "SSIDiagnosticaHouse" || layoutData.code === "CSolutionHouse" || layoutData.code === "FibiaHaslevHouse" || layoutData.code === "LauritzenHouse") {
                    return this.strings().visionIngenAftale;
                } else if (layoutData.code === "ErhvershusMidt") {
                    return this.strings().ErhvervmidtjyllandNoAppointment;
                } else if (layoutData.code === "Kjaergaard") {
                    return this.getText().toUpperCase();
                }
                break;
            case "Checkout":
                if (layoutData.code === "Reisswolf") {
                    return this.strings().checkudresswolf;
                } else if (layoutData.code === "Kjaergaard") {
                    return this.getText().toUpperCase();
                }
                break;
            case "Event":
                break;
        }
        return this.getText();
    }

    private getPurpose = (): LocalizationKey => {
        const { type } = this.props;

        switch (type) {
            case "Carpenter":
                return "purposeCarpenter";
            case "Applicant":
                return "purposeApplicant";
            case "Guest":
                return "purposeGuest";
            case "HotWork":
                return "HotWork";
            case "NotHotWork":
                return "NotHotWork";
            default:
                return "purposeEmpty";
        }
    }

    private addIcon() {
        const { layoutData, type } = this.props

        if (layoutData.code === "JKF") {

            switch (type) {
                case "Carrier":
                    return (<img src={this.getIconPath()} style={{ width: 150, height: 90 }} alt="Courier button" />);
                default:
                    return (<FontIcon iconName="Contact" className="flow-card-icon" />);
            }

        } else if (layoutData.code === "Kilde") {

            switch (type) {
                case "Carrier":
                    return (<img src={this.getIconPath()} style={{ width: 130, height: 70, marginRight: -65 }} alt="Courier button" />);
                default:
                    return (<FontIcon iconName="Contact" className="flow-card-icon" />);
            }

        } else if (layoutData.code === "ErhvershusMidt") {

            switch (type) {
                case "CheckinNoInfo":
                    return (<img src={this.getIconPath()} style={{ width: 90, height: 90, filter: "invert(100%)" }} alt="No info button" />);
                case "NoAppointment":
                    return (<img src={this.getIconPath()} style={{ width: 90, height: 90, filter: "invert(100%)" }} alt="No appointment button" />);
                default:
                    return (<FontIcon iconName="Contact" className="flow-card-icon" />);
            }
        } else if (layoutData.code === "Kjaergaard") {

            switch (type) {
                case "Appointment":
                    return (<img src={this.getIconPath()} style={{ width: 160, height: 160 }} alt="Appointment button" />);
                case "NoAppointment":
                    return (<img src={this.getIconPath()} style={{ width: 160, height: 160 }} alt="No appointment button" />);
                case "Checkout":
                    return (<img src={this.getIconPath()} style={{ width: 160, height: 160 }} alt="Checkout button" />);
                default:
                    return (<FontIcon iconName="Contact" className="flow-card-icon" />);
            }
        } else if (layoutData.code === "Dragsbaek") {
            return (<FontIcon iconName="Contact" className="PhoneFriendly-flow-card-icon" />)
        } else if (layoutData.code === "DSStaal") {

            switch (type) {
                case "Appointment":
                    return (<img src={this.getIconPath()} style={{ width: 160, height: 160 }} alt="Appointment button" />);
                case "Checkout":
                    return (<img src={this.getIconPath()} style={{ width: 160, height: 160 }} alt="Checkout button" />);
                case "Carpenter":
                    return (<img src={this.getIconPath()} style={{ width: 160, height: 160 }} alt="Carpenter button" />);
                case "ExternalService":
                    return (<img src={this.getIconPath()} style={{ width: 160, height: 160 }} alt="Carpenter button" />);
                default:
                    return (<FontIcon iconName="Contact" className="flow-card-icon" />);
            }
        } else {
            return (<FontIcon iconName="Contact" className="flow-card-icon" />)
        }
    }

    render() {
        const { click, cardKey, layoutData, type } = this.props;

        if (layoutData.code === "Kilde" && type === "Carrier") { // With icon
            return (
                <div style={StylesUtil.getFlowCardContainerStyle(layoutData)}
                    onClick={() => click(cardKey, this.strings()[this.getPurpose()])} >

                    <div style={StylesUtil.getFlowCardTextStyle(layoutData)}>
                        {this.getModifiedText()}
                    </div>
                    {this.addIcon()}
                </div>
            );
        } else if (layoutData.code === "DSStaal") { // With icon
            return (
                <div style={StylesUtil.getFlowCardContainerStyle(layoutData)}
                    onClick={() => click(cardKey, this.strings()[this.getPurpose()])} >

                    {this.addIcon()}
                    <div style={StylesUtil.getFlowCardTextStyle(layoutData)}>
                        {this.getModifiedText()}
                    </div>
                </div>
            );
        } else if (layoutData.code === "Flensted" || layoutData.code === "FlenstedWorkshop" || layoutData.code === "SociatyOfLife" || layoutData.code === "Retograad" || layoutData.code === "OfficePartner" || layoutData.code === "EDForhandler" || layoutData.code === "Visionhouse" || layoutData.code === "VismaHouse" || layoutData.code === "SSIDiagnosticaHouse" || layoutData.code === "CSolutionHouse" || layoutData.code === "Reisswolf" || layoutData.code === "LouisPoulsen" || layoutData.code === "FibiaHaslevHouse" || layoutData.code === "Lauritzen" || layoutData.code === "LauritzenHouse" || layoutData.code === "Bulkers" || layoutData.code === "Kilde" || layoutData.code === "Haugen" || layoutData.code === "Meneta" || layoutData.code === "TotalEnergies") { // When not utilizing icon
            return (
                <div style={StylesUtil.getFlowCardContainerStyle(layoutData)}
                    onClick={() => click(cardKey, this.strings()[this.getPurpose()])} >

                    <div style={StylesUtil.getFlowCardTextStyle(layoutData)}>
                        {this.getModifiedText()}
                    </div>
                </div>
            );
        } else { // With icon
            return (
                <div style={StylesUtil.getFlowCardContainerStyle(layoutData)}
                    onClick={() => click(cardKey, this.strings()[this.getPurpose()])} >
                    {this.addIcon()}

                    <div style={StylesUtil.getFlowCardTextStyle(layoutData)}>
                        {this.getModifiedText()}
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state: IApplicationState): Partial<ILocalizationState & ILayoutDataState & IConfigDataState> => {
    const { language } = state.localization;
    const { layoutData } = state.layoutData
    const { languageData } = state.configData;

    return {
        language, layoutData, languageData
    };
};

export default connect(
    mapStateToProps
)(FlowCard);
