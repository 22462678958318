import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ClientConfig from '../../../config/clientConfig';
import { PrimaryButton, TextField } from '@fluentui/react'
import { IApplicationState } from '../../../store';
import { resetStoreState, setCurrentFlow, setCurrentPage, setPhoneNumberExists } from '../../../store/Flow/actions';
import { IFlowState } from '../../../store/Flow/types';
import { LocalizationDictionary } from '../../../localization/localizationDictionary';
import { ICustomerDataState } from '../../../store/CustomerData/types';
import { setCustomerData } from '../../../store/CustomerData/action';
import KeyboardWrapper from '../../LayoutElements/VirtualKeyboard/KeyboardWrapper';
import Keyboard from "react-simple-keyboard";

import './index.css';
import { dispatchRequest } from '../../../api/apiUtils';
import { checkClientExistsRequest, getClientByPhoneRequest, isCheckedOutRequest, getAttributesById } from '../../../api/clientApi';
import { ILocalizationState } from '../../../store/Localization/types';
import { ILayoutDataState, KeyboardLayoutDesign } from '../../../store/LayoutData/types';
import { getCompanyGuidFromId } from '../../../api/companyApi';
import { IConfigDataState } from '../../../store/ConfigData/types';
import StylesUtil from '../../../utils/StylesUtil';
import { setCurrentDepartment } from '../../../store/LayoutData/actions';
import { sendSMSRequest } from '../../../api/checkinApi';

interface IDispatchProps {
    setCurrentPage: typeof setCurrentPage;
    setCurrentFlow: typeof setCurrentFlow;
    setPhoneNumberExists: typeof setPhoneNumberExists;
    setCustomerData: typeof setCustomerData;
    resetStoreState: typeof resetStoreState;
    setCurrentDepartment: typeof setCurrentDepartment;
}

interface IState {
    phoneNumber: string;
    continueEnabled: boolean;
    checked: boolean;
    validNumber: boolean;
    isPhoneNumber: boolean
}

type Props = IDispatchProps & IFlowState & ICustomerDataState & ILocalizationState & ILayoutDataState & IConfigDataState;


class NumberPage extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props)

        this.state = {
            phoneNumber: "",
            continueEnabled: false,
            checked: false,
            validNumber: false,
            isPhoneNumber: true
        }
    }

    private readonly strings = LocalizationDictionary.getStrings;
    private readonly keyboard: React.MutableRefObject<typeof Keyboard> = React.createRef();

    private checkIfPhoneNumberExists = async (companyId: number, phoneNumber: string) => {
        return dispatchRequest(await checkClientExistsRequest(companyId, phoneNumber));
    }

    private getCustomerByPhone = async (companyId: number, phoneNumber: string) => {
        return dispatchRequest(await getClientByPhoneRequest(companyId, phoneNumber));
    }

    private getCustomerAttribute = async (customerId: number) => {
        return dispatchRequest(await getAttributesById(customerId));
    }

    private checkIfClientIsCheckedOut = async (companyId: number, phoneNumber: string) => {
        return dispatchRequest(await isCheckedOutRequest(companyId, phoneNumber));
    }

    private onKeyboardInputChange = (input: string) => {
        const { configData } = this.props;

        var number = input.replace(/[^0-9+]/g, '');
        number = number.replace(/(?<=.)\+/g, '');
        var phoneNumberLength = 8;
        if (configData.useKeyCard) {
            number = input;
            phoneNumberLength = 1;
        }


        this.setState({ phoneNumber: number });

        const validNumber = number && number.length >= phoneNumberLength
        this.setState({ continueEnabled: this.state.checked || validNumber, validNumber: validNumber })
    }

    private onNumberInputChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const { layoutData, configData } = this.props;
        //remove mask from number string
        if (newValue[0] != '+')
            newValue.replace('+', '')
        var number = newValue.replace(/[^0-9+]/g, '');
        number = number.replace(/(?<=.)\+/g, '');
        var phoneNumberLength = 8;
        if (configData.useKeyCard) {
            number = newValue;
            phoneNumberLength = 1;
        }

        this.setState({ phoneNumber: number });
        if (layoutData.code !== "Dragsbaek") {
            (this.keyboard.current as any).setInput(number);
        }

        const validNumber = number && number.length >= phoneNumberLength
        this.setState({ continueEnabled: this.state.checked || validNumber, validNumber: validNumber })
    }



    private nextButtonClicked = async () => {
        const { currentFlow, setCurrentPage, setCurrentFlow, setCustomerData, setPhoneNumberExists, configData, departmentsData, language, languageData, setCurrentDepartment } = this.props;
        const { data, layoutData, conditionData } = this.props;
        const { phoneNumber } = this.state
        let companyId = await dispatchRequest(await getCompanyGuidFromId()); // TODO: Temporary untill we get validation
        var currentCondition = conditionData.find(({ localizationCode }) => localizationCode === language);
        //console.log("currentFlow: " + currentFlow);

        data.phoneNumber = phoneNumber;
        setCustomerData(data);
        // proceed to next step of the flow
        if (await this.checkIfPhoneNumberExists(companyId, phoneNumber) && layoutData.code !== "Sef" && layoutData.code !== "Seftest") { // Customer exists
            setPhoneNumberExists(true);

            var client = await this.getCustomerByPhone(companyId, phoneNumber);
            var customerInfos = await this.getCustomerAttribute(client.id) as CustomerInfoDTO[]
            if (layoutData.code === "Kalundborg") {
                var temp = new CustomerDTO();
                temp.companyId = companyId
                temp.firstName = client.firstName
                temp.secondName = client.secondName
                temp.phoneNumber = phoneNumber
                temp.customerInfos = customerInfos
                //console.log(temp);
                setCustomerData(temp);

            } else {
                client.customerInfos = customerInfos;
                setCustomerData(client);
            }

            // Check if customer attributes are filled for language
            var currentLanguageOptions = languageData.find(({ localizationCode }) => localizationCode === language);
            const attributes = currentLanguageOptions && currentLanguageOptions.checkInCustomAttributes ? currentLanguageOptions.checkInCustomAttributes : [];

            var hasCustomFields = false;
            if (attributes.length > 0) {
                attributes.forEach(att => {
                    customerInfos.forEach(ci => {
                        if (att.id === ci.companyAttributeId) {
                            hasCustomFields = true;
                        }
                    })
                })
            }


            if (layoutData.code === "DSStaal") { // fuel to the fire...
                let companyId = await dispatchRequest(await getCompanyGuidFromId());
                const smsInfo: SMSInfoDTO = {
                    flowType: 5,
                    companyId,
                    appointmentId: -1,
                    recipient: data.phoneNumber,
                    localizationCode: language,
                    customMessage: this.getCustomMessage(layoutData.code, language)
                }

                try {
                    await dispatchRequest(await sendSMSRequest(smsInfo));
                }
                catch {
                    console.log("Could not send SMS.");
                }
            }

            if (layoutData.code === "Fundamentet" && await this.checkIfClientIsCheckedOut(companyId, phoneNumber) === false) {
                setCurrentFlow("Checkout" as FlowType);
                setCurrentPage("MessagePage" as PageType);
            } else if (attributes.length > 0 && !hasCustomFields) {
                switch (currentFlow) {
                    case "CheckinAppointment":
                    case "GroupCheckin":
                    case "CheckinNoAppointment":
                        setCurrentPage("CustomFieldsPage" as PageType);
                        break;
                    case "Checkout":
                        setCurrentPage("MessagePage" as PageType);
                        break;
                    case "Other":
                        if (layoutData.code === "Meneta") {
                            setCurrentPage("MainFieldsPage" as PageType)
                            break;
                        }
                }
            } else {
                switch (currentFlow) {
                    case "CheckinAppointment":
                        if (layoutData.code === "DSStaal") {
                            setCurrentPage("MainFieldsPage" as PageType)
                            break;
                        } else if (layoutData.code === "Kjaergaard") {
                            setCurrentPage("MainFieldsPage" as PageType);
                            break;
                        } else if (configData.useClientDepartments && departmentsData && departmentsData.length > 0) {
                            if (departmentsData.length === 1) {
                                setCurrentDepartment(0);
                                setCurrentPage("EmployeePage" as PageType);
                            } else {
                                setCurrentPage("DepartmentPage" as PageType);
                            }
                            break;
                        } else {
                            if (layoutData.code === "FriskSnit") {
                                setCurrentPage("MessagePage" as PageType);
                                break;
                            }
                            setCurrentPage("EmployeePage" as PageType);
                            break;
                        }
                    case "CheckinNoAppointment":
                        if (layoutData.code === "Kjaergaard") {
                            setCurrentPage("MainFieldsPage" as PageType);
                            break;
                        }
                        else if (ClientConfig.flow.informAllEnabled) {
                            if (currentCondition && currentCondition.useVideo && currentCondition.videoURL !== null && currentCondition.videoURL.trim() !== "") {
                                setCurrentPage("ConditionVideoPage" as PageType);
                            } else {
                                setCurrentPage("MessagePage" as PageType);
                            }
                        }
                        else {
                            if (configData.useClientDepartments && departmentsData && departmentsData.length > 0) {
                                if (departmentsData.length === 1) {
                                    setCurrentDepartment(0);
                                    setCurrentPage("EmployeePage" as PageType);
                                } else {
                                    setCurrentPage("DepartmentPage" as PageType);
                                }
                            } else {
                                setCurrentPage("EmployeePage" as PageType);
                            }
                        }
                        break;
                    case "Checkout":
                        setCurrentPage("MessagePage" as PageType);
                        break;
                    case "GroupCheckin":
                        setCurrentPage("MainFieldsPage" as PageType)
                        break;
                    case "Carpenter":
                    case "Other":
                        setCurrentPage("MainFieldsPage" as PageType)
                        break;
                }
            }
        }
        else { // Customer does NOT exists
            //console.log(currentFlow)
            switch (currentFlow) {
                case "CheckinAppointment":
                case "GroupCheckin":
                case "Carpenter":
                case "CheckinNoAppointment":
                    setCurrentPage("MainFieldsPage" as PageType);
                    //console.log("Done")
                    break;
                case "Checkout":
                    setCurrentPage("MessagePage" as PageType);
                    break;
                case "Other":
                    if (layoutData.code === "Meneta") {
                        setCurrentPage("MainFieldsPage" as PageType)
                        break;
                    }
            }
        }
    }

    private getCustomMessage(layoutDataCode: string, language: string): string {
        switch (layoutDataCode) {
            case 'DSStaal':

                switch (language) {
                    case 'DK':
                        return "Velkommen til DS Stålkonstruktion. Klik på linket for at se vores beredskabsplaner: www.ds-staal.dk/da-dk/beredskabsplan";
                    case 'EN':
                        return "Welcome to DS Stålkonstruktion. Follow the link to read our contingency plans: https://www.ds-staal.dk/da-dk/beredskabsplan-en";
                    default:
                        return "Message not available for this language.";
                }

            default:
                return "Customer " + layoutDataCode + " not supported";
        }
    }

    private addStyleButton(): any {
        const { layoutData } = this.props

        if (layoutData.code === "SociatyOfLife") {
            return ({ position: 'absolute', top: 400, left: 1500 })
        } else if (layoutData.code === "OfficePartner") {
            return ({ position: 'absolute', top: '31.5%', left: '82%' })
        }

    }

    private addTextField(phoneNumber: any) {
        const { layoutData } = this.props

        if (ClientConfig.layoutVersion === 'v3' && layoutData.code === "SociatyOfLife") {
            return (<TextField styles={StylesUtil.getTextFieldStyles(layoutData, false)} value={phoneNumber} placeholder="Phone number" onChange={this.onNumberInputChange} autoFocus />)

        } else if (layoutData.code === "OfficePartner") {
            return (<div style={{ top: '30%', position: 'absolute' }}><TextField placeholder={this.strings().numberPlaceholder} styles={StylesUtil.getTextFieldStyles(layoutData, false)} value={phoneNumber} onChange={this.onNumberInputChange}
                underlined={ClientConfig.layoutVersion !== 'v1'}
                autoFocus /> </div>);

        } else {
            return (<TextField styles={StylesUtil.getTextFieldStyles(layoutData, false)} value={phoneNumber} onChange={this.onNumberInputChange}
                underlined={ClientConfig.layoutVersion !== 'v1'}
                autoFocus />);
        }
    }

    private addKeyboard() {
        const { layoutData } = this.props

        if (layoutData.code === "OfficePartner") {
            return (<div style={{ width: 1010, position: 'absolute', left: 35, top: '40%' }}> <KeyboardWrapper keyboardRef={this.keyboard} onChange={this.onKeyboardInputChange} input={this.state.phoneNumber} layoutDesign={KeyboardLayoutDesign.NUMPAD} layoutName='numpad' /></div>);
        } else if (layoutData.code === "Dragsbaek") {

        } else {
            return (<KeyboardWrapper keyboardRef={this.keyboard} onChange={this.onKeyboardInputChange} input={this.state.phoneNumber} layoutDesign={KeyboardLayoutDesign.NUMPAD} layoutName='numpad' />);
        }
    }

    private addNumberPageTitle() {
        const { layoutData } = this.props

        if (layoutData.code === "OfficePartner") {
            return (<h2 style={{ position: 'absolute', top: '25%' }}>{this.strings().numberPageTitle}</h2>)
        }

    }

    private addCheckBox() {
        const { layoutData, configData, currentFlow } = this.props;

        if (!configData.useKeyCard) {
        }
        else if (layoutData.code === "FlenstedWorkshop" && currentFlow !== 'Checkout') {
            return (<div className="check-box-and-message">
                <label className="condition-check-box">
                    <input style={StylesUtil.getCheckMarkStyle(layoutData)}
                        type="checkbox"
                        checked={this.state.checked}
                        autoComplete="new-password"
                        onChange={() => {
                            this.setState({ continueEnabled: !this.state.checked || this.state.validNumber, checked: !this.state.checked })
                        }}
                    />
                </label>
                <p className="condition-message2" style={({ color: ClientConfig.mainTextColor })}>{this.strings().keyCardCheckBox}</p>
            </div>)
        }
    }


    render() {
        const { currentPage, layoutData } = this.props;
        const { phoneNumber, continueEnabled } = this.state;
        //console.log(continueEnabled);

        if (currentPage === "PhoneNumberPage") {
            return (
                <div className="main-fields-container">
                    <div className="number-text-field-container">
                        {this.addNumberPageTitle()}
                        {this.addTextField(phoneNumber)}

                    </div>
                    <div className="main-fields-button-container">
                        <PrimaryButton style={this.addStyleButton()} styles={StylesUtil.getButtonStyles(layoutData)} className="main-fields-button" text={this.strings().next} onClick={this.nextButtonClicked} disabled={!continueEnabled} allowDisabledFocus />
                        {this.addCheckBox()}
                    </div>
                    {this.addKeyboard()}
                </div>
            );
        } else {
            return (<div>Error</div>);
        }

    }
}

const mapStateToProps = (state: IApplicationState): Partial<IFlowState & ICustomerDataState & ILocalizationState & ILayoutDataState & IConfigDataState> => {
    const { currentPage, currentFlow } = state.flow;
    const { data } = state.customerData;
    const { language } = state.localization;
    const { layoutData, conditionData, departmentsData } = state.layoutData;
    const { configData, languageData } = state.configData;

    return {
        currentPage, currentFlow, data, language, layoutData, conditionData, configData, departmentsData, languageData
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return bindActionCreators(
        { setCurrentPage, setCurrentFlow, setPhoneNumberExists, setCustomerData, resetStoreState, setCurrentDepartment },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NumberPage);


class CustomerInfoDTO {
    companyAttributeId: number;
    attributeValue: string;
}

class CustomerDTO {
    firstName: string;
    secondName: string;
    phoneNumber: string;
    companyId: number;
    customerInfos: CustomerInfoDTO[];
}