import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ClientConfig from '../../../config/clientConfig';
import { PrimaryButton } from '@fluentui/react'
import { IApplicationState } from '../../../store';
import { resetStoreState, setCurrentPage } from '../../../store/Flow/actions';
import { IFlowState } from '../../../store/Flow/types';
import { LocalizationDictionary } from '../../../localization/localizationDictionary';

import './index.css';
import { ILayoutDataState } from '../../../store/LayoutData/types';
import StylesUtil from '../../../utils/StylesUtil';
import { ILocalizationState } from '../../../store/Localization/types';
import { IConfigDataState } from '../../../store/ConfigData/types';
import { setCurrentDepartment } from '../../../store/LayoutData/actions';

interface ILogo {
    logo: any;
}

interface IState {
    continueEnabled: boolean;
    checked: boolean;
    pdfDone: boolean;
}

interface IDispatchProps {
    setCurrentPage: typeof setCurrentPage;
    resetStoreState: typeof resetStoreState;
    setCurrentDepartment: typeof setCurrentDepartment;
}

type Props = IDispatchProps & IFlowState & ILogo & ILayoutDataState & ILocalizationState & IConfigDataState;

class ConditionPdfPage extends React.Component<Props, IState> {
    public readonly state: IState = {
        continueEnabled: false,
        checked: false,
        pdfDone: false,
    }

    componentWillMount() {
        var conditionData = this.props.conditionData.find(({ localizationCode }) => localizationCode === this.props.language)
        if (!conditionData || (!conditionData.usePdf && !conditionData.useCondition && !conditionData.useVideo))
            this.nextButtonClicked(); //Conditions have not been enabled for this language, continue
    }

    private readonly strings = LocalizationDictionary.getStrings;

    private nextButtonClicked = () => {
        const { currentFlow, setCurrentPage, layoutData, conditionData, configData, departmentsData, language, setCurrentDepartment } = this.props;
        var currentCondition = conditionData.find(({ localizationCode }) => localizationCode === language);

        setCurrentPage("CustomFieldsPage" as PageType);

        switch (currentFlow) {
            case "CheckinAppointment":
                if (layoutData.code === "EDForhandler" || layoutData.code === "Reisswolf") {
                    setCurrentPage("MainFieldsPage" as PageType)
                    break;
                } else if (layoutData.code === "HIN") {
                    if (configData.useClientDepartments && departmentsData && departmentsData.length > 0) {
                        if (departmentsData.length === 1) {
                            setCurrentDepartment(0);
                            setCurrentPage("EmployeePage" as PageType);
                        } else {
                            setCurrentPage("DepartmentPage" as PageType);
                        }
                    } else {
                        setCurrentPage("EmployeePage" as PageType);
                    }
                    break;
                } else {
                    setCurrentPage("PhoneNumberPage" as PageType)
                    break;
                }
            case "CheckinNoAppointment":
                if (layoutData.code === "EDForhandler" || layoutData.code === "Sef" || layoutData.code === "Seftest" || layoutData.code === "ErhvershusMidt" || layoutData.code === "HIN") {
                    if (currentCondition && currentCondition.useVideo && currentCondition.videoURL !== null && currentCondition.videoURL.trim() !== "") {
                        setCurrentPage("ConditionVideoPage" as PageType);
                        break;
                    } else {
                        setCurrentPage("MessagePage" as PageType);
                        break;
                    }
                } else if (layoutData.code === "Kjaergaard") {
                    setCurrentPage("PhoneNumberPage" as PageType);
                    break;
                } else if (layoutData.code === "Meneta") {
                    setCurrentPage("PhoneNumberPage" as PageType);
                }
                break;
            case "Checkout":
                if (layoutData.code === "Reisswolf") {

                    //Add new page to view currently checked in
                    setCurrentPage("CheckoutListPage" as PageType);
                    break;
                } else {
                    setCurrentPage("PhoneNumberPage" as PageType);
                    break;
                }
            case "Carrier":
                setCurrentPage("MessagePage" as PageType);
                break;
            case "HR":
                setCurrentPage("MessagePage" as PageType);
                break;
            case "Other":
                if (layoutData.code === "Meneta") {
                    setCurrentPage("PhoneNumberPage" as PageType)
                    break;
                }
                setCurrentPage("MessagePage" as PageType);
                break;
            case "CheckinNoInfo":
                if (configData.useClientDepartments && departmentsData && departmentsData.length > 0) {
                    if (departmentsData.length === 1) {
                        setCurrentDepartment(0);
                        setCurrentPage("EmployeePage" as PageType);
                    } else {
                        setCurrentPage("DepartmentPage" as PageType);
                    }
                } else {
                    setCurrentPage("EmployeePage" as PageType);
                }
                break;
            case "GroupCheckin":
                setCurrentPage("PhoneNumberPage" as PageType)
                break;
            case "LeverandoerCheckin":
                setCurrentPage("MainFieldsPage" as PageType)
                break;
            case "Event":
                setCurrentPage("MainFieldsPage" as PageType)
                break;
            case "Carpenter":
                setCurrentPage("PhoneNumberPage");
                break;
        }
    }

    private addStyleButton(): any {
        const { layoutData } = this.props

        if (layoutData.code === "OfficePartner") {
            return ({ position: 'absolute', top: '31.5%', left: '82%' })
        }

    }

    // TODO: Special logo case
    private addMainFieldsPageTitle() {
        const { logo, layoutData } = this.props

        if (layoutData.code === "OfficePartner") {
            return (<h2 style={{ position: 'absolute', top: '25%' }}>{this.strings().mainfieldsPageTitle}</h2>)
        } else if (layoutData.code === "Reisswolf") {
            return (<div>
                <img className="logo" src={logo} onClick={this.iconClick} alt="Reisswolf logo" />
                <h2 className="Reisswolf-title" style={{ color: ClientConfig.mainColor }}>{this.strings().checkinreisswolf}</h2>
            </div>)
        }
    }

    private addCheckBox() {
        const { conditionData, layoutData, language } = this.props;
        var currentCondition = conditionData.find(({ localizationCode }) => localizationCode === language);

        if (layoutData.code === "Dragsbaek") {
            return (<div className="check-box-and-message">
                <label className="PhoneFriendly-condition-check-box">
                    <input style={StylesUtil.getCheckMarkStyle(layoutData)}
                        type="checkbox"
                        checked={this.state.checked}
                        autoComplete="new-password"
                        onChange={() => {
                            this.setState({ continueEnabled: !this.state.checked, checked: !this.state.checked })
                        }}
                    />
                </label>
                <p className="PhoneFriendly-condition-message2" style={({ color: ClientConfig.mainTextColor })}>{currentCondition.pdfConfirmText}</p>
            </div>)
        } else {
            return (<div className="check-box-and-message">
                <label className="condition-check-box">
                    <input style={StylesUtil.getCheckMarkStyle(layoutData)}
                        type="checkbox"
                        checked={this.state.checked}
                        autoComplete="new-password"
                        onChange={() => {
                            this.setState({ continueEnabled: !this.state.checked, checked: !this.state.checked })
                        }}
                    />
                </label>
                <p className="condition-message2" style={({ color: ClientConfig.mainTextColor })}>{currentCondition.pdfConfirmText}</p>
            </div>)
        }
    }

    private addNextButton(buttonDisabled: boolean) {
        const { layoutData } = this.props

        if (layoutData.code === "Reisswolf") {
            return (
                <button className="condition-continue2-button" style={buttonDisabled ? { fontFamily: 'Inter', backgroundColor: 'rgb(243, 242, 241)', color: 'rgb(210, 208, 206)' } : { fontFamily: 'Inter', backgroundColor: ClientConfig.mainColor, color: ClientConfig.mainTextColor }} onClick={this.nextButtonClicked} disabled={buttonDisabled}>{this.strings().continue}</button>
            )
        } else if (layoutData.code === "Dragsbaek") {
            return (<div style={{ marginTop: '1vh' }}>
                <PrimaryButton style={this.addStyleButton()} styles={StylesUtil.getButtonStyles(layoutData)} className="custom-fields-button" text={this.strings().next} onClick={this.nextButtonClicked} disabled={buttonDisabled} allowDisabledFocus />
            </div>);
        } else {
            return (<div style={{ marginTop: '10px' }}>
                <PrimaryButton style={this.addStyleButton()} styles={StylesUtil.getButtonStyles(layoutData)} className="custom-fields-button" text={this.strings().next} onClick={this.nextButtonClicked} disabled={buttonDisabled} allowDisabledFocus />
            </div>);
        }
    }

    private addPdf() {
        const { layoutData, conditionData, language } = this.props
        var currentCondition = conditionData.find(({ localizationCode }) => localizationCode === language);

        if (layoutData.code === "Reisswolf") {
            return (<div className="Reisswolf-pdf-container">
                <object
                    aria-label="Important PDF"
                    data={`${currentCondition.pdfURL}#toolbar=0`}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>)
        } else if (layoutData.code === "HIN") {
            return (<div className="Hin-pdf-container">
                <object
                    aria-label="Important PDF"
                    data={`${currentCondition.pdfURL}#toolbar=0`}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>);
        } else if (layoutData.code === "Kjaergaard") {
            return (<div className="Kjaergaard-pdf-container">
                <object
                    aria-label="Important PDF"
                    data={`${currentCondition.pdfURL}#toolbar=0`}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>);
        } else if (layoutData.code === "Dragsbaek") {
            return (<div className="PhoneFriendly-pdf-container">
                <object
                    aria-label="Important PDF"
                    data={`${currentCondition.pdfURL}#toolbar=0`}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>);
        } else if (layoutData.code === "Flensted" || layoutData.code === "FlenstedWorkshop") {
            return (<div className="Flensted-pdf-container">
                <object
                    aria-label="Important PDF"
                    data={`${currentCondition.pdfURL}#toolbar=0`}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>);
        } else if (layoutData.code === "Meneta") {
            return (<div className="Meneta-pdf-container">
                <object
                    aria-label="Important PDF"
                    data={`${currentCondition.pdfURL}#toolbar=0`}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>);
        } else {
            return (<div className="pdf-container">
                <object
                    aria-label="Important PDF"
                    data={`${currentCondition.pdfURL}#toolbar=0`}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>);
        }
    }

    private iconClick = () => {
        const { resetStoreState } = this.props;

        resetStoreState();
    }

    render() {
        const { currentPage } = this.props;
        const { continueEnabled } = this.state;

        if (currentPage === "ConditionPdfPage") {
            return (
                <div>
                    {this.addPdf()}
                    <div className="main-fields-container">
                        <div className="main-text-fields-container">
                            {this.addMainFieldsPageTitle()}
                        </div>
                        <div className="button-text-container">
                            {this.addCheckBox()}
                            {this.addNextButton(!continueEnabled)}
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (<div>Error</div>);
        }

    }
}

const mapStateToProps = (state: IApplicationState): Partial<IFlowState & ILayoutDataState & ILocalizationState & IConfigDataState> => {
    const { currentPage, currentFlow } = state.flow;
    const { layoutData, conditionData, departmentsData } = state.layoutData;
    const { configData } = state.configData;
    const { language } = state.localization;

    return {
        currentPage, currentFlow, layoutData, conditionData, configData, departmentsData, language
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return bindActionCreators(
        { setCurrentPage, resetStoreState, setCurrentDepartment },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConditionPdfPage);
