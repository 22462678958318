import { HubConnectionBuilder } from '@microsoft/signalr';
import { ZebraPrintGuestCard } from './printUtils'

const signalRHubUrl = window.location.toString().includes('localhost')
    ? "https://localhost:44308/printHub"
    : "https://bkwebapi.app-infotv.net/printHub";

let configData: MainConfigDTO
const retryTimes = [0, 3000, 10000, 60000];

export const initSignalR = (_configData: MainConfigDTO) => {
    const connection = new HubConnectionBuilder()
        .withAutomaticReconnect(
            {
                nextRetryDelayInMilliseconds: context => {
                    const index = context.previousRetryCount < retryTimes.length ? context.previousRetryCount : retryTimes.length - 1;
                    return retryTimes[index];
                }
            }
        )
        .withUrl(signalRHubUrl)
        .build();
    configData = _configData
    connection.start()
        .then(() => {
            console.log('Connected to SignalR hub')
            RegisterCheckinClient()
        })
        .catch(err => console.error('Error connecting to hub:', err));

    connection.onreconnected(() => {
        console.log('Reconnected to SignalR hub');
        RegisterCheckinClient()
    });

    connection.on("PrintGuestCard", guestCardInfo => {
        if (!configData.checkInPrinterSettings) {
            console.log("No printer set up")
            return;
        }
        var guestCardInfoAsJson = JSON.parse(guestCardInfo)
        ZebraPrintGuestCard(configData.checkInPrinterSettings, guestCardInfoAsJson.FullName, guestCardInfoAsJson.CompanyName, guestCardInfoAsJson.PhoneNumber)
    });

    function RegisterCheckinClient() {
        let params = new URLSearchParams(document.location.search);
        connection.send('RegisterCheckinClient',
            params.get('companyId')
        )
    }
}